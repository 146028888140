import React from 'react'
import { FormControl } from 'react-bootstrap'
import { SearchIcon } from '../SVGIcons'
import "./index.scss"

export const Search = ({ search, handleSearchBar, placeholder }) => {
    return (
        <div className='search'>
            <div className='search-container'>
                <SearchIcon />
                <FormControl
                    placeholder={placeholder}
                    aria-label="Search"
                    value={search}
                    onChange={(e) => handleSearchBar(e)}
                    aria-describedby="Search"
                />
            </div>
        </div>)
}
