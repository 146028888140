import { combineReducers } from "redux";
import authReducer from "./authReducers";
import dashBoardReducer from "./dashBoardReducer";
import offerManagementReducer from "./offerManagementReducer";
import orderReducer from "./orderReducer";
import productReducer from "./productReducer";
import promotionManagementReducer from "./promotionManagementReducer";
import userManagementReducer from "./userManagementReducer";
import profileReducer from "./profileReducer";
import storyReducer from "./storyReducer";


const appReducers = combineReducers({
    authReducer,
    dashBoardReducer,
    userManagement:userManagementReducer,
    products:productReducer,
    orders:orderReducer,
    promotions:promotionManagementReducer,
    offers:offerManagementReducer,
    profile:profileReducer,
    story:storyReducer

})
export default appReducers;