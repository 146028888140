import React from 'react'
import OtpInput from 'react-otp-input';
import "./index.scss";


const OTPInputComponent = ({ handleChange, otp, numInputs, separator }) => {

    return (
        <div className='otp-form d-flex justify-content-center my-4'>
            <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={numInputs}
                separator={separator}
            />
        </div>
    )
}


export default OTPInputComponent