import React, { useState } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap';
import { Search, Typography } from '..';
import "./index.scss";

export const MoreLists = ({ list, label }) => {
    const [search, setSearch] = useState('');

    const handleSearch = (e) => setSearch(e.target.value);

    const getList = () => (list?.length && list?.filter((item) => search?.length ? item.name?.toLowerCase().includes(search?.toLowerCase()) : item.name)?.slice(0, 20)) || [];

    return (
        <Row>
            <Col xs={'12'} className='p-0'>
                <Row>
                    <Col xs={12}>
                        <Typography variant={'heading4'} label={label} margin="mb-3" />
                    </Col>
                </Row>
                <Row className='moreList'>
                    {list?.length > 0 &&
                        <Col xs={12}>
                            <Search search={search} handleSearchBar={handleSearch} placeholder={"Search..."} />
                        </Col>}
                    <Col xs={12} className={list?.length > 0 ? 'moreListBody mt-3' : 'moreListBody mt-3'} >
                        {getList()?.length > 0 ?
                            getList()?.map((item, key) => (
                                <ListGroup key={item?.id} className="w-100">
                                    <ListGroup.Item className="w-100">{item?.name}</ListGroup.Item>
                                </ListGroup>
                            )) :
                            (<ListGroup className="w-100">
                                <ListGroup.Item className="w-100 text-center color-info font-15 font-semibold bg-secondary p-3">No Data</ListGroup.Item>
                            </ListGroup>)}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
