import React, { useState } from 'react'
import Downshift from 'downshift'
import { Placeholder } from 'react-bootstrap'
import CustomCheckbox from '../Checkbox'
import { FilterClose } from '../SVGIcons'
import './index.scss'
import { useDispatch, useSelector } from 'react-redux'
import { actionGetProduct } from '../../actions/Dashboard/Products'

import { SnackMessages } from '../Toast'

let timeout = 0;
export const PickItem = ({ placeholder, setMenuOpen, menuIsOpen, handleSearchFilter, viewBy, className, activeItems, onSelect, noDataText, orderSummary = [] }) => {
    const { activeProductSortOption } = useSelector((state) => state?.products)
    const dispatch = useDispatch()
    const [menuList, setMenuList] = useState([])
    const [listLoader, setListLoader] = useState(false);
    const [pageProps, setPageProps] = useState({
        limit: 100,
        page: 0,
        search: '',
        sort: {
            sortOrder: "asc",
            sortBy: activeProductSortOption?.value || "most-purchased",
        },
        total: 0,
        params: []
    })
    const { showErrorMessage } = SnackMessages();

    const fetchStyles = (item) => item?.isChecked ? 'active' : ''



    const handleChooseItem = (element) => {

        let newList = [...menuList]
        const index = newList.findIndex(item => item?.customerName === element?.customerName)
        newList[index].isChecked = newList[index]?.isChecked === 0 ? 1 : 0
        setMenuList(newList)
        handleSearchFilter(placeholder, element)
    }

    const handleCloseList = () => {
        setMenuList([]);
        setMenuOpen(false)
    }

    const onInputValueChange = (selection) => {
        if (selection !== undefined) {
            setMenuOpen(true)
            setListLoader(true)
            getSearchByCustomer(selection)
        }
    }

    const isItemExist = (label) => activeItems?.length && activeItems?.find(item => item?.customerName === label) ? true : false;

    const getSearchByCustomer = async (searchText) => {

        if (timeout) {
            clearTimeout(timeout);
            timeout = 0;
        }
        if (searchText?.length) {
            timeout = setTimeout(async () => {
                const offset = (pageProps.limit * pageProps.page)
                const { limit, search, sort, params } = pageProps;
                const res = await dispatch(actionGetProduct(limit, offset, searchText, sort, { viewby: viewBy }))
                if (!res.success) {
                    showErrorMessage(res.message)
                } else {
                    const { response } = res;
                    const newResult = res?.data?.length && res?.data?.map(result => ({
                        ...result,
                        isChecked: isItemExist(result?.customerName),
                    }))
                    // get all products already asdded

                    setMenuList(newResult)
                    setListLoader(false)
                }
            }, 1000);
        } else {
            setMenuList([])
            setListLoader(false)
        }
    }

    return (
        <div className={`search-select ${className ? className : ''}`} >
            <Downshift
                onOuterClick={handleCloseList}
                isOpen={menuIsOpen}
                onSelect={(selection) => handleChooseItem(selection)}
                onInputValueChange={onInputValueChange}
                itemToString={item => (item ? item.name?.toLowerCase() : '')}>
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    inputValue,
                    isOpen
                }) => (
                    <div className='search-filter'>

                        <input {...getInputProps()} placeholder={placeholder} className="form-control" />
                        {<>
                            {listLoader ? <CustomLoader /> : <ul {...getMenuProps()}>
                                {isOpen && <>
                                    {menuList?.length > 0
                                        ? menuList.filter(item => (!inputValue || item.title?.toLowerCase().includes(inputValue.toLowerCase())) && !orderSummary?.map(each => each.product.id).includes(item.id))
                                            .map((item, index) => (
                                                <li
                                                    {...getItemProps({
                                                        key: index,
                                                        index,
                                                        item,
                                                    })}
                                                    onClick={() => onSelect(item)}
                                                    className={`search-list ${fetchStyles(item)}`}>
                                                    <p>{item?.title}</p>
                                                </li>
                                            )) :
                                        (<div className='user-not-found'>
                                            <p>{noDataText}</p>
                                        </div>)
                                    }

                                </>}

                            </ul>}
                            {isOpen && <div className='suggestion-close '>
                                <FilterClose onClick={handleCloseList} />
                            </div>}

                        </>}
                    </div>
                )}
            </Downshift>
        </div>
    )
}

const CustomLoader = () => {
    return (
        <div className=' gap-2 custom-loader'>
            {Array.from(Array(4).keys()).map(load => (
                <Placeholder key={load} animation="glow">
                    <Placeholder className="w-100 p-3 gap-2 mt-2" />
                </Placeholder>
            ))}

        </div>
    )

}