import { CREATE_OFFER_BEGINS, CREATE_OFFER_FAILURE, CREATE_OFFER_SUCCESS, DELETE_IMAGE_BEGINS, DELETE_IMAGE_FAILURE, DELETE_IMAGE_SUCCESS, DELETE_OFFER_BEGINS, DELETE_OFFER_FAILURE, DELETE_OFFER_SUCCESS, GET_OFFERS_DETAILS_BEGINS, GET_OFFERS_DETAILS_FAILURE, GET_OFFERS_DETAILS_SUCCESS, GET_OFFERS_LIST_BEGINS, GET_OFFERS_LIST_FAILURE, GET_OFFERS_LIST_SUCCESS, UPDATE_OFFER_BEGINS, UPDATE_OFFER_FAILURE, UPDATE_OFFER_SUCCESS } from "../constants"

const INITIAL_STATE = {
    offerList: {
        data: [],
        total: 0
    },
    offerListLoader: false,
    offerDetails: {},
    offerDetailsLoader: false,
    createOfferLoader: false,
    imageDeleteLoader: false
}

const offerManagementReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_OFFERS_LIST_BEGINS:
            return { ...state, offerListLoader: true }
        case GET_OFFERS_LIST_SUCCESS:
            return { ...state, offerList: action?.data, offerListLoader: false }
        case GET_OFFERS_LIST_FAILURE:
            return { ...state, offerListLoader: false }

        case GET_OFFERS_DETAILS_BEGINS:
            return { ...state, offerDetailsLoader: true }
        case GET_OFFERS_DETAILS_SUCCESS:
            return { ...state, offerDetails: action?.data, offerDetailsLoader: false }
        case GET_OFFERS_DETAILS_FAILURE:
            return { ...state, offerDetailsLoader: false }

        case CREATE_OFFER_BEGINS:
            return { ...state, createOfferLoader: true }
        case CREATE_OFFER_SUCCESS:
            return {
                ...state, createOfferLoader: false,
                offerList: {
                    data: [...[action?.data], ...state?.offerList?.data || []],
                    total: state.offerList.total + 1
                }
            }
        case CREATE_OFFER_FAILURE:
            return { ...state, createOfferLoader: false }

        case UPDATE_OFFER_BEGINS:
            return { ...state, createOfferLoader: true }

        case UPDATE_OFFER_SUCCESS:
            const { resData, id } = action?.data;
            const selectedIndex = state.offerList.data.findIndex(item => Number(item.id) === Number(id));
            const newData = [...state.offerList.data];
            newData[selectedIndex] = { ...newData[selectedIndex], ...resData };
            return { ...state, offerList: { ...state.offerList, data: newData }, createOfferLoader: false }
        case UPDATE_OFFER_FAILURE:
            return { ...state, createOfferLoader: false }

        case DELETE_IMAGE_BEGINS:
            return { ...state, imageDeleteLoader: true }
        case DELETE_IMAGE_SUCCESS:
            return { ...state, imageDeleteLoader: false }
        case DELETE_IMAGE_FAILURE:
            return { ...state, imageDeleteLoader: false }

        case DELETE_OFFER_BEGINS:
            return { ...state, offerDetailsLoader: true }
        case DELETE_OFFER_SUCCESS:
            const newOfferList = state.offerList.data.filter(item => Number(item.id) !== Number(action?.data?.id));
            return { ...state, offerList: { ...state.offerList, data: newOfferList }, offerDetailsLoader: false }
        case DELETE_OFFER_FAILURE:
            return { ...state, offerDetailsLoader: false }
            
        default:
            return state;
    }
}
export default offerManagementReducer;
