import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import AllComponents from '../views/AllComponents';
import { Authentication } from '../views/Authentication';
import { DashBoard } from '../views/Dashboard';
import { NotFound } from '../components';

const DefaultRoutes = () => {
    const { isAuthenticated } = useSelector((state) => state?.authReducer)
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/auth/login"} />} />
                    <Route path="/auth/*" element={<Authentication />} />
                    <Route path="/*" element={isAuthenticated ? <DashBoard /> : <Navigate to="/auth/login" />} />
                    <Route path="/components/*" element={isAuthenticated ? <AllComponents /> : <Navigate to="/auth/login" />} />
                    <Route path="/404" element={<NotFound />} />
                </Routes>
            </BrowserRouter>

        </>
    )
}

export default DefaultRoutes;

