import React, { Suspense } from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import { Avatar, Card, NotFound, } from '../../components';
import { GlobalSpinner } from '../../components/Spinner';
import "./index.scss";


const Login = React.lazy(() => import('./Login'));
const ForgotPassword = React.lazy(() => import('./ForgotPassword'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
const Message = React.lazy(() => import('./OTP'));

export const Authentication =  React.memo(() => {
 
    return (
        <Suspense fallback={<GlobalSpinner />}>
            <div className='authentication auth-bg'>
                <div className='row auth-row mx-0 g-0 flex-lg-row'>
                    <div className='col-12 col-lg-6 d-flex align-items-center justify-content-center p-5 auth-col'>
                            <Avatar variant={'logo-login'} bsPrefix src="/images/logo-v.svg" />
                    </div>
                    <div className='col-12 col-lg-6 auth-col'>
                        <Card variant={'login-card'}>
                            <Routes>
                                <Route path="/" element={<Navigate to="/auth/login" />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/forgot-password" element={<ForgotPassword />} />
                                <Route path="/reset-password" element={<ResetPassword />} />
                                <Route path="/generate-otp" element={<Message />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </Card>
                    </div>
                </div>
            </div>
        </Suspense>)
})

