import React from 'react'
import { Button } from 'react-bootstrap'

import './index.scss'

export const Buttons = ({ variant, icon, label, disabled, onClick, pos, type, count, customClass,form}) => {

    const renderButtonComponent = () => {
        switch (variant) {
            case 'btn-primary-lg':
                return <Button type={type}  onClick={onClick} disabled={disabled} className={`btn-primary-lg ${customClass ? customClass : ''}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'btn-primary-md':
                return <Button type={type} form={form} onClick={onClick} disabled={disabled} className={`btn-primary-md ${customClass ? customClass : ''}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'btn-secondary-md':
                return <Button type={type}  onClick={onClick} disabled={disabled} className={`btn-secondary-md ${customClass ? customClass : ''}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'link-info':
                return <Button type={type} onClick={onClick} disabled={disabled} className={`link-info ${customClass ? customClass : ''}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'link-secondary':
                return <Button type={type} onClick={onClick} disabled={disabled} className={`link-secondary ${customClass ? customClass : ''}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'link-dark':
                return <Button type={type} onClick={onClick} disabled={disabled} className={`link-dark ${customClass ? customClass : ''}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'btn-link':
                return <Button type={type} onClick={onClick} disabled={disabled} variant="link" className={customClass}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'secondary':
                return <Button type={type} onClick={onClick} disabled={disabled} variant="secondary" className={customClass}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'primary-shadow':
                return <Button type={type} onClick={onClick} disabled={disabled} variant="primary-shadow" className={customClass}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'secondary-shadow':
                return <Button type={type}  onClick={onClick} disabled={disabled} variant="secondary-shadow" className={customClass}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'secondary-gray':
                return <Button type={type} onClick={onClick} disabled={disabled} className={customClass} variant="secondary-gray">{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} {count && Number(count) > 0 ? <span className='count'>{count}</span> :''} </Button>
            case 'info':
                return <Button type={type} onClick={onClick} disabled={disabled} variant="info" className={customClass}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'outline-primary':
                return <Button onClick={onClick} disabled={disabled} className={`btn-outline-primary ${customClass ? customClass : ''}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'outline-secondary':
                return <Button onClick={onClick} disabled={disabled} className={`btn-outline-secondary ${customClass ? customClass : ''}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>

            case 'primary-rounded':
                return <Button type={type} onClick={onClick} disabled={disabled} variant="primary-rounded" className={customClass}>{icon} {count && Number(count) > 0 ? <span className='count'>{count}</span> :''} </Button>

            case 'secondary-rounded':
                return <Button type={type} onClick={onClick} disabled={disabled} className={customClass} variant="secondary-rounded">{icon} {count && Number(count) > 0 ? <span className='count'>{count}</span> :''} </Button>

            case 'white-rounded':
                return <Button type={type} onClick={onClick} disabled={disabled} variant="white-rounded" className={customClass}>{icon} {count && Number(count) > 0  ? <span className='count'>{count}</span> :''} </Button>

            case 'outline-white':
                return <Button onClick={onClick} disabled={disabled} className={`btn-outline-white ${customClass ? customClass : ''}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>

            case 'outline-gray':
                return <Button onClick={onClick} disabled={disabled} className={`btn-outline-gray ${customClass ? customClass : ''}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>

            default:
                return <Button type={type} onClick={onClick} disabled={disabled} variant="primary" className={customClass}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
        }


    }


    return (
        <div className='Buttons'>

            {renderButtonComponent()}

        </div>
    )
}
