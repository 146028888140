import React from 'react'
import { Form } from 'react-bootstrap'
import "./index.scss"

export default function CustomCheckbox({ variant, label, id, name, onChange, checked, setFieldValue, disabled }) {
    const renderCheckBox = () => {
        switch (variant) {
            case 'labelled':

                return (<Form.Group className="labelled" controlId={id}>
                    <Form.Check checked={Boolean(checked)} disabled={disabled} onChange={(e) => onChange(e, name, setFieldValue)} type="checkbox" name={name} label={label} />
                </Form.Group>)
            case 'labelled-options':

                return (<Form.Group className="labelled-options" controlId={id}>
                    <Form.Check checked={Boolean(checked)} disabled={disabled} onChange={(e) => onChange(e, name, setFieldValue)} type="checkbox" name={name} label={label} />
                </Form.Group>)
            case 'table-check':

                return (<Form.Group className="labelled-options" controlId={id}>
                    <Form.Check checked={Boolean(checked)} disabled={disabled} onChange={(e)=>onChange(e)} type="checkbox" name={name} label={label} />
                </Form.Group>)

            default:
                return (<Form.Group controlId={id}>
                    <Form.Check checked={checked} onChange={(e) => onChange(e, name, setFieldValue)} type="checkbox" name={name} label={''} />
                </Form.Group>)
        }

    }


    return (
        <div className='custom-checkbox'> {renderCheckBox()}</div>
    )
}
