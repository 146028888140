import {
    ADD_NEW_UNIT_BEGINS,
    ADD_NEW_UNIT_FAILURE,
    ADD_NEW_UNIT_SUCCESS,
    APPROVE_ORDER_REQUEST_BEGINS, APPROVE_ORDER_REQUEST_FAILURE, APPROVE_ORDER_REQUEST_SUCCESS,
    CHANGE_CUSTOMER_STATUS_BEGINS, CHANGE_CUSTOMER_STATUS_FAILURE, CHANGE_CUSTOMER_STATUS_SUCCESS, CREATE_BULK_ORDER_BEGINS, CREATE_BULK_ORDER_FAILURE, CREATE_BULK_ORDER_SUCCESS, CREATE_ORDER_BEGINS, CREATE_ORDER_FAILURE, CREATE_ORDER_SUCCESS, DELETE_ORDER_SUMMARY_BEGINS,
    DELETE_ORDER_SUMMARY_FAILURE, DELETE_ORDER_SUMMARY_SUCCESS, GET_ADDRESS_LIST_BEGINS, GET_ADDRESS_LIST_FAILURE,
    GET_ADDRESS_LIST_SUCCESS, GET_DELIVERED_ORDER_DETAILS_BY_ID_BEGINS, GET_DELIVERED_ORDER_DETAILS_BY_ID_FAILURE, GET_DELIVERED_ORDER_DETAILS_BY_ID_SUCCESS, GET_OPEN_ORDER_DETAILS_BY_ID_BEGINS, GET_OPEN_ORDER_DETAILS_BY_ID_FAILURE,
    GET_OPEN_ORDER_DETAILS_BY_ID_SUCCESS, GET_ORDERS_BEGINS, GET_ORDERS_FAILURE, GET_ORDERS_SUCCESS,
    GET_ORDER_DETAILS_BY_ID_BEGINS, GET_ORDER_DETAILS_BY_ID_FAILURE, GET_ORDER_DETAILS_BY_ID_SUCCESS,
    GET_REQUEST_ORDER_DETAILS_BY_ID_BEGINS, GET_REQUEST_ORDER_DETAILS_BY_ID_FAILURE, GET_REQUEST_ORDER_DETAILS_BY_ID_SUCCESS,
    GET_SEARATES_DETAILS_BEGINS,
    GET_SEARATES_DETAILS_FAILURE,
    GET_SEARATES_DETAILS_SUCCESS,
    GET_UNIT_LIST_BEGINS,
    GET_UNIT_LIST_FAILURE,
    GET_UNIT_LIST_SUCCESS,
    UPDATE_ALL_ORDER_BEGINS, UPDATE_ALL_ORDER_FAILURE, UPDATE_ALL_ORDER_SUCCESS, UPDATE_CHOOSED_ADDRESSES_BEGINS, UPDATE_CHOOSED_ADDRESSES_FAILURE, UPDATE_CHOOSED_ADDRESSES_SUCCESS, UPDATE_ORDER_CREDIT_HOLD_BEGINS, UPDATE_ORDER_CREDIT_HOLD_FAILURE, UPDATE_ORDER_CREDIT_HOLD_SUCCESS, UPDATE_ORDER_DESCRIPTION_BEGINS,
    UPDATE_ORDER_DESCRIPTION_FAILURE, UPDATE_ORDER_DESCRIPTION_SUCCESS, UPDATE_ORDER_PRICE_BEGINS, UPDATE_ORDER_PRICE_FAILURE, UPDATE_ORDER_PRICE_SUCCESS, UPDATE_PRODUCT_DESCRIPTION_BEGINS,
    UPDATE_PRODUCT_DESCRIPTION_FAILURE, UPDATE_PRODUCT_DESCRIPTION_SUCCESS,
    UPDATE_SHIPPING_ADDRESS_BEGINS, UPDATE_SHIPPING_ADDRESS_FAILURE, UPDATE_SHIPPING_ADDRESS_SUCCESS
} from "../../../constants";
import api from "../../../utils/axios";

const getOrderBegins = () => ({ type: GET_ORDERS_BEGINS })
const getOrderSuccess = (data) => ({ type: GET_ORDERS_SUCCESS, data })
const getOrderFailure = () => ({ type: GET_ORDERS_FAILURE })

const getOpenOrderDetailsByIdBegins = () => ({ type: GET_OPEN_ORDER_DETAILS_BY_ID_BEGINS })
const getOpenOrderDetailsByIdSuccess = (data) => ({ type: GET_OPEN_ORDER_DETAILS_BY_ID_SUCCESS, data })
const getOpenOrderDetailsByIdFailure = () => ({ type: GET_OPEN_ORDER_DETAILS_BY_ID_FAILURE })

const getDeliveredOrderDetailsByIdBegins = () => ({ type: GET_DELIVERED_ORDER_DETAILS_BY_ID_BEGINS })
const getDeliveredOrderDetailsByIdSuccess = (data) => ({ type: GET_DELIVERED_ORDER_DETAILS_BY_ID_SUCCESS, data })
const getDeliveredOrderDetailsByIdFailure = () => ({ type: GET_DELIVERED_ORDER_DETAILS_BY_ID_FAILURE })

const getOrderDetailsByIdBegins = () => ({ type: GET_ORDER_DETAILS_BY_ID_BEGINS })
const getOrderDetailsByIdSuccess = (data) => ({ type: GET_ORDER_DETAILS_BY_ID_SUCCESS, data })
const getOrderDetailsByIdFailure = () => ({ type: GET_ORDER_DETAILS_BY_ID_FAILURE })

const getRequestOrderDetailsByIdBegins = () => ({ type: GET_REQUEST_ORDER_DETAILS_BY_ID_BEGINS })
const getRequestOrderDetailsByIdSuccess = (data) => ({ type: GET_REQUEST_ORDER_DETAILS_BY_ID_SUCCESS, data })
const getRequestOrderDetailsByIdFailure = () => ({ type: GET_REQUEST_ORDER_DETAILS_BY_ID_FAILURE })

const changeCustomerStatusBegins = () => ({ type: CHANGE_CUSTOMER_STATUS_BEGINS })
const changeCustomerStatusSuccess = (data) => ({ type: CHANGE_CUSTOMER_STATUS_SUCCESS, data })
const changeCustomerStatusFailure = () => ({ type: CHANGE_CUSTOMER_STATUS_FAILURE })

const updateOrderDescriptionBegins = () => ({ type: UPDATE_ORDER_DESCRIPTION_BEGINS })
const updateOrderDescriptionSuccess = (data) => ({ type: UPDATE_ORDER_DESCRIPTION_SUCCESS, data })
const updateOrderDescriptionFailure = () => ({ type: UPDATE_ORDER_DESCRIPTION_FAILURE })

const updateProductDescriptionBegins = () => ({ type: UPDATE_PRODUCT_DESCRIPTION_BEGINS })
const updateProductDescriptionSuccess = (data) => ({ type: UPDATE_PRODUCT_DESCRIPTION_SUCCESS, data })
const updateProductDescriptionFailure = () => ({ type: UPDATE_PRODUCT_DESCRIPTION_FAILURE })

const approveOrderRequestBegins = () => ({ type: APPROVE_ORDER_REQUEST_BEGINS })
const approveOrderRequestSuccess = (data) => ({ type: APPROVE_ORDER_REQUEST_SUCCESS, data })
const approveOrderRequestFailure = () => ({ type: APPROVE_ORDER_REQUEST_FAILURE })

const updateShippingAddressBegins = () => ({ type: UPDATE_SHIPPING_ADDRESS_BEGINS })
const updateShippingAddressSuccess = (data) => ({ type: UPDATE_SHIPPING_ADDRESS_SUCCESS, data })
const updateShippingAddressFailure = () => ({ type: UPDATE_SHIPPING_ADDRESS_FAILURE })

const getAddressListBegins = () => ({ type: GET_ADDRESS_LIST_BEGINS })
const getAddressListSuccess = (data) => ({ type: GET_ADDRESS_LIST_SUCCESS, data })
const getAddressListFailure = () => ({ type: GET_ADDRESS_LIST_FAILURE })

const deleteOrderSummaryBegins = () => ({ type: DELETE_ORDER_SUMMARY_BEGINS })
const deleteOrderSummarySuccess = () => ({ type: DELETE_ORDER_SUMMARY_SUCCESS })
const deleteOrderSummaryFailure = () => ({ type: DELETE_ORDER_SUMMARY_FAILURE })

const updateAllOrderBegins = () => ({ type: UPDATE_ALL_ORDER_BEGINS })
const updateAllOrderSuccess = () => ({ type: UPDATE_ALL_ORDER_SUCCESS })
const updateAllOrderFailure = () => ({ type: UPDATE_ALL_ORDER_FAILURE })

const updateChoosedAddressBegins = () => ({ type: UPDATE_CHOOSED_ADDRESSES_BEGINS })
const updateChoosedAddressSuccess = () => ({ type: UPDATE_CHOOSED_ADDRESSES_SUCCESS })
const updateChoosedAddressFailure = () => ({ type: UPDATE_CHOOSED_ADDRESSES_FAILURE })

const updateOfferPriceBegins = () => ({ type: UPDATE_ORDER_PRICE_BEGINS })
const updateOfferPriceSuccess = () => ({ type: UPDATE_ORDER_PRICE_SUCCESS })
const updateOfferPriceFailure = () => ({ type: UPDATE_ORDER_PRICE_FAILURE })


const addNewUnitBegins = () => ({ type: ADD_NEW_UNIT_BEGINS });

const addNewUnitSuccess = () => ({ type: ADD_NEW_UNIT_SUCCESS });

const addNewUnitFails = () => ({ type: ADD_NEW_UNIT_FAILURE });

const getUnitListBegins = () => ({ type: GET_UNIT_LIST_BEGINS });

const getUnitListSuccess = (data) => ({ type: GET_UNIT_LIST_SUCCESS, data });

const getUnitListFails = () => ({ type: GET_UNIT_LIST_FAILURE });

const createOrderBegins = () => ({ type: CREATE_ORDER_BEGINS })
const createOrderSuccess = (data) => ({ type: CREATE_ORDER_SUCCESS, data })
const createOrderFailure = () => ({ type: CREATE_ORDER_FAILURE })

const updateCreditHoldStatusBegins = () => ({ type: UPDATE_ORDER_CREDIT_HOLD_BEGINS })
const updateCreditHoldStatusSuccess = (data) => ({ type: UPDATE_ORDER_CREDIT_HOLD_SUCCESS, data })
const updateCreditHoldStatusFailure = () => ({ type: UPDATE_ORDER_CREDIT_HOLD_FAILURE })

const createBulkOrderBegins = () => ({ type: CREATE_BULK_ORDER_BEGINS })
const createBulkOrderSuccess = (data) => ({ type: CREATE_BULK_ORDER_SUCCESS, data })
const createBulkOrderFailure = () => ({ type: CREATE_BULK_ORDER_FAILURE })

const getSearatesDetailsBegins = () => ({ type: GET_SEARATES_DETAILS_BEGINS })
const getSearatesDetailsSuccess = (data) => ({ type: GET_SEARATES_DETAILS_SUCCESS, data })
const getSearatesDetailsFailure = () => ({ type: GET_SEARATES_DETAILS_FAILURE })

//GET ORDERS
export const actionGetOrders = (limit, offset, viewby) => async (dispatch) => {
    dispatch(getOrderBegins())
    try {
        const res = await api.get(`/admin/orders`,
            { params: { limit, offset, viewby } })
        if (res.status === 200) {
            const { data, total } = res?.data
            const response = { data, total } || { response: { data: [], total: 0 } }
            dispatch(getOrderSuccess(response))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getOrderFailure())
        return { success: false, message: message }
    }
}

// GET OPEN ORDER DETAILS BY ID
export const actionGetOpenOrderDetailsById = (id) => async (dispatch) => {
    dispatch(getOpenOrderDetailsByIdBegins())
    try {
        const res = await api.get(`/admin/orders/${id}/open-order-details`)
        if (res.status === 200) {
            const { data } = res?.data
            dispatch(getOpenOrderDetailsByIdSuccess(data))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getOpenOrderDetailsByIdFailure())
        return { success: false, message: message }
    }
}

// GET DELIVERED ORDER DETAILS BY ID
export const actionGetDeliveredOrderDetailsById = (id) => async (dispatch) => {
    dispatch(getDeliveredOrderDetailsByIdBegins())
    try {
        const res = await api.get(`/admin/orders/${id}/history-order-details`)
        if (res.status === 200) {
            const { data } = res?.data
            dispatch(getDeliveredOrderDetailsByIdSuccess(data))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getDeliveredOrderDetailsByIdFailure())
        return { success: false, message: message }
    }
}

// GET ORDER DETAILS BY ID
export const actionGetOrderDetailsById = (id) => async (dispatch) => {
    dispatch(getOrderDetailsByIdBegins())
    try {
        const res = await api.get(`/admin/orders/${id}/order-details`)
        if (res.status === 200) {
            const { data } = res?.data
            dispatch(getOrderDetailsByIdSuccess(data))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getOrderDetailsByIdFailure())
        return { success: false, message: message }
    }
}

// GET REQUEST ORDER DETAILS BY ID
export const actionGetRequestOrderDetailsById = (id) => async (dispatch) => {
    dispatch(getRequestOrderDetailsByIdBegins())
    try {
        const res = await api.get(`/admin/orders/${id}/request-order-details`)
        if (res.status === 200) {
            const { data } = res?.data
            dispatch(getRequestOrderDetailsByIdSuccess(data))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getRequestOrderDetailsByIdFailure())
        return { success: false, message: message }
    }
}

// CHANGE CUSTOMER STATUS
export const actionChangeCustomerStatus = (data) => async (dispatch) => {
    dispatch(changeCustomerStatusBegins());
    try {
        const res = await api.put('/admin/orders/update-customer-status', data)
        if (res.status === 200) {
            const { message } = res?.data;
            dispatch(changeCustomerStatusSuccess())
            return { success: true, message }
        }
    } catch (error) {

        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(changeCustomerStatusFailure())
        return { success: false, message: message }
    }
}

// UPDATE ORDER DESCRIPTION
export const actionUpdateOrderDescription = (data) => async (dispatch) => {
    dispatch(updateOrderDescriptionBegins());
    try {
        const res = await api.put('/admin/orders/update-description', data)
        if (res.status === 200) {
            const { message } = res?.data
            dispatch(updateOrderDescriptionSuccess(data))
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateOrderDescriptionFailure())
        return { success: false, message: message }
    }
}

// UPDATE PRODUCT DESCRIPTION
export const actionUpdateProductDescription = (data) => async (dispatch) => {
    dispatch(updateProductDescriptionBegins());
    try {
        const res = await api.put('/admin/orders/update-product-description', data)
        if (res.status === 200) {
            const { message } = res?.data
            dispatch(updateProductDescriptionSuccess(data))
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateProductDescriptionFailure())
        return { success: false, message: message }
    }
}

//APPROVE ORDER REQUEST
export const actionApproveOrderRequest = (data) => async (dispatch) => {
    dispatch(approveOrderRequestBegins());
    try {
        const res = await api.post('/admin/orders/approve', data)
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Order Approved Successfully' }
            dispatch(approveOrderRequestSuccess())
            return { success: true, message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(approveOrderRequestFailure())
        return { success: false, message: message }
    }
}

//UPDATE SHIPPING ADDRESS
export const actionUpdateShippingAddress = (data) => async (dispatch) => {
    dispatch(updateShippingAddressBegins());
    try {
        const res = await api.post('/admin/add-address', data)
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Address Added Successfully' }
            dispatch(updateShippingAddressSuccess())
            return { success: true, message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateShippingAddressFailure())
        return { success: false, message: message }
    }
}

//GET ADDRESS LIST
export const actionGetAddressList = (limit, offset) => async (dispatch) => {
    dispatch(getAddressListBegins())
    try {
        const res = await api.get(`/admin/address`, { params: { limit, offset } })
        if (res.status === 200) {
            const { data, total } = res?.data
            const response = { data, total } || { response: { data: [], total: 0 } }
            dispatch(getAddressListSuccess(response))
            return { success: true, message: 'success' }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getAddressListFailure())
        return { success: false, message: message }
    }
}

// DELETE ORDER SUMMARY
export const actionDeleteOrderSummary = (data) => async (dispatch) => {
    dispatch(deleteOrderSummaryBegins())
    try {
        const res = await api.delete(`/admin/orders/delete-products`, { params: data })
        if (res.status === 200) {
            const { message } = res?.data
            dispatch(deleteOrderSummarySuccess())
            return { success: true, message }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(deleteOrderSummaryFailure())
        return { success: false, message: message }
    }
}

// UPDATE ALL ORDER
export const actionUpdateAllOrder = (data) => async (dispatch) => {
    dispatch(updateAllOrderBegins());
    try {
        const res = await api.put('/admin/orders/update', data)
        if (res.status === 200) {
            const { message } = res?.data
            dispatch(updateAllOrderSuccess())
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateAllOrderFailure())
        return { success: false, message: message }
    }
}

// UPDATE CHOOSED ADDRESS
export const actionUpdateChoosedAddress = (data) => async (dispatch) => {
    dispatch(updateChoosedAddressBegins());
    try {
        const res = await api.put('/admin/orders/update-address', data)
        if (res.status === 200) {
            const { message } = res?.data;
            dispatch(updateChoosedAddressSuccess())
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateChoosedAddressFailure())
        return { success: false, message: message }
    }
}

// UPDATE OFFER PRICE
export const actionUpdateOfferPrice = (data) => async (dispatch) => {
    dispatch(updateOfferPriceBegins());
    try {
        const res = await api.post('/admin/orders/update-offer-amount', data)
        if (res.status === 200) {
            const { message } = res?.data
            dispatch(updateOfferPriceSuccess())
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateOfferPriceFailure())
        return { success: false, message: message }
    }
}

// ADD NEW UNIT
export const actionAddNewUnit = (payload) => async (dispatch) => {
    dispatch(addNewUnitBegins());
    try {
        const res = await api.post('/admin/orders/product-unit', payload);
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Unit added successfully' }
            dispatch(addNewUnitSuccess());
            return { success: true, message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(addNewUnitFails());
        return { success: false, message: message }
    }
}

// GET UNIT LIST
export const actionGetUnitList = ({ customerId }) => async (dispatch) => {
    dispatch(getUnitListBegins());
    try {
        const res = await api.get('/admin/custom-product-units', { params: { customerId } });
        if (res.status === 200) {
            const { message, data } = res?.data;
            dispatch(getUnitListSuccess(data));
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getUnitListFails());
        return { success: false, message: message }
    }
}

//CREATE ORDER
export const actionCreateOrder = (data) => async (dispatch) => {
    dispatch(createOrderBegins());
    try {
        const res = await api.post('/admin/order-request/order', data)
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Order Created Successfully' }
            dispatch(createOrderSuccess())
            return { success: true, message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(createOrderFailure())
        return { success: false, message: message }
    }
}


//MARK AS COMPLETE ORDER
export const actionConfirmeOrder = (data) => async (dispatch) => {
    dispatch(createOrderBegins());
    try {
        const res = await api.post('/admin/order-request/complete', data)
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Order Completed Successfully' }
            dispatch(createOrderSuccess())
            return { success: true, message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(createOrderFailure())
        return { success: false, message: message }
    }
}


//ADD PRODUCTS ON ORDER SUMMARY
export const actionUpdateOrderSummary = (data) => async (dispatch) => {
    dispatch(createOrderBegins());
    try {
        const res = await api.post('/admin/order-request/add-product', data)
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Order Completed Successfully' }
            dispatch(createOrderSuccess())
            return { success: true, message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(createOrderFailure())
        return { success: false, message: message }
    }
}

// UPDATE CREDIT HOLD
export const actionUpdateCreditHold = (id, data) => async (dispatch) => {
    dispatch(updateCreditHoldStatusBegins());
    try {
        const res = await api.put(`/admin/ordres/${id}/credit-hold-status`, data)
        if (res.status === 200) {
            const { message } = res?.data;
            dispatch(updateCreditHoldStatusSuccess())
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(updateCreditHoldStatusFailure())
        return { success: false, message: message }
    }
}

//BULK ORDER CREATE
export const actionCreateBulkOrder = (id) => async (dispatch) => {
    dispatch(createBulkOrderBegins());
    try {
        const res = await api.post(`/admin/orders/${id}/bulk-order`)
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Bulk Order Created Successfully' }
            dispatch(createBulkOrderSuccess())
            return { success: true, message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(createBulkOrderFailure())
        return { success: false, message: message }
    }
}

// GET SEARATES LIST
export const actionGetSearatesDetails = (payload) => async (dispatch) => {
    dispatch(getSearatesDetailsBegins());
    try {
        const res = await api.get('/customer/orders/shipping-details', { params: { container_number: payload } });
        if (res.status === 200) {
            const { message, data } = res?.data;
            dispatch(getSearatesDetailsSuccess(data));
            return { success: true, message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getSearatesDetailsFailure());
        return { success: false, message: message }
    }
}