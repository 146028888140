// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_s-CVolO78hgfxtKL_zf13rV857hWDuk",
  authDomain: "tab-surfaces.firebaseapp.com",
  projectId: "tab-surfaces",
  storageBucket: "tab-surfaces.appspot.com",
  messagingSenderId: "271828039257",
  appId: "1:271828039257:web:7566a68cfeb4a50358a348",
  measurementId: "G-NC6Y89GZ1H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig,{
  experimentalForceLongPolling: true, // this line
  useFetchStreams: false, // and this line
});
export const auth = getAuth(app);
export const db = getFirestore(app);
