import React, { useState } from 'react'
import Downshift from 'downshift'
import { Placeholder } from 'react-bootstrap'
import CustomCheckbox from '../Checkbox'
import { FilterClose } from '../SVGIcons'
import './index.scss'
import { useDispatch } from 'react-redux'
import { actionSearchCustomerEmailByName } from '../../actions/Dashboard/UserManagement'
import { SnackMessages } from '../Toast'

let timeout = 0;
export const PickCustomerEmailWithSuggestions = ({ id, type, placeholder, setMenuOpen, menuIsOpen, handleSearchFilter, userRole, className, activeItems }) => {
    const dispatch = useDispatch()
    const [menuList, setMenuList] = useState([])
    const [listLoader, setListLoader] = useState(false)
    const { showErrorMessage } = SnackMessages();

    const fetchStyles = (item) => item?.isChecked ? 'active' : ''



    const handleChooseItem = (element) => {

        let newList = [...menuList]
        const index = newList.findIndex(item => item?.name === element?.name)
        newList[index].isChecked = newList[index]?.isChecked === 0 ? 1 : 0
        setMenuList(newList)
        handleSearchFilter(placeholder, element)
    }

    const handleCloseList = () => {
        setMenuList([]);
        setMenuOpen(false)
    }

    const onInputValueChange = (selection) => {
        if (selection !== undefined) {
            setMenuOpen(true)
            setListLoader(true)
            getSearchByCustomer(selection)
        }
    }

    const isItemExist = (label) =>  activeItems?.length && activeItems?.find(item => item?.name === label) ? true : false;
    
    const getSearchByCustomer = async (searchText) => {

        if (timeout) {
            clearTimeout(timeout);
            timeout = 0;
        }
        const payload = {
            search: searchText,
            usage_status: "not-invited"
            // role_id: userRole,
        }
        const assignCustomerPayload = {
            search: searchText,
            except: id
        }
        if (searchText?.length) {
            timeout = setTimeout(async () => {
                const res = await dispatch(actionSearchCustomerEmailByName(type === "assignCustomer" ? assignCustomerPayload : payload))
                if (!res.success) {
                    showErrorMessage(res.message)
                } else {
                    const { response } = res;
                    const newResult = response?.length && response.map(result => ({
                        ...result,
                        isChecked: isItemExist(result?.name),
                    }))
                    setMenuList(newResult)
                    setListLoader(false)
                }

            }, 1000);
        } else {
            setMenuList([])
            setListLoader(false)
        }
    }

    return (
        <div className={`search-select ${className ? className : ''}`} >
            <Downshift
                onOuterClick={handleCloseList}
                isOpen={menuIsOpen}
                onSelect={(selection) => handleChooseItem(selection)}
                onInputValueChange={onInputValueChange}
                itemToString={item => (item ? item.name?.toLowerCase() : '')}>
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    inputValue,
                    isOpen
                }) => (
                    <div className='search-filter'>

                        <input {...getInputProps()} placeholder={placeholder} className="form-control" />
                        {<>
                            {listLoader ? <CustomLoader /> : <ul {...getMenuProps()}>
                                {isOpen && <>
                                    {menuList?.length > 0
                                        ? menuList.filter(item => !inputValue || item.name?.toLowerCase().includes(inputValue.toLowerCase()))
                                            .map((item, index) => (
                                                <li

                                                    {...getItemProps({
                                                        key:index,
                                                        index,
                                                        item,
                                                    })}
                                                    className={`search-list ${fetchStyles(item)}`}>
                                                    <CustomCheckbox variant="labelled"
                                                        checked={Boolean(item?.isChecked)}
                                                        label={item?.name} />
                                                </li>
                                            )) :
                                        (<div className='user-not-found'>
                                            <p>No User found</p>
                                        </div>)
                                    }

                                </>}

                            </ul>}
                            {isOpen && <div className='suggestion-close '>
                                <FilterClose onClick={handleCloseList} />
                            </div>}

                        </>}
                    </div>
                )}
            </Downshift>
        </div>
    )
}

const CustomLoader = () => {
    return (
        <div className=' gap-2 custom-loader'>
            {Array.from(Array(4).keys()).map(load => (
                <Placeholder key={load} animation="glow">
                    <Placeholder className="w-100 p-3 gap-2 mt-2" />
                </Placeholder>
            ))}

        </div>
    )

}