import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import "./index.scss"

const CustomTabs = ({ data, selected, variant, onChange }) => {
    const [key, setKey] = useState(selected);

    const renderTabs = () => {
        switch (variant) {
            case 'tab-with-button':
                return (
                    <div className='tab-button-type'>
                        <Tabs defaultActiveKey="profile"
                            id="tab-button-type"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3">
                            {data?.length &&
                                data?.map((tab, index) =>
                                    <Tab eventKey={tab?.title}
                                        key={index}
                                        title={tab?.title}
                                        disabled={tab?.disabled}>
                                        {tab?.children}
                                    </Tab>)}
                        </Tabs>
                    </div>)
            case 'tab-with-border':
                return (
                    <div className='tab-with-border gap-3 mt-3 d-flex flex-column'>
                        <Tabs defaultActiveKey="profile"
                            id="tab-with-border"
                            activeKey={selected}
                            onSelect={(selected) => onChange(selected)}
                            className="mb-0">
                            {data?.length &&
                                data?.map((tab, index) =>
                                    <Tab eventKey={tab?.title}
                                        key={index}
                                        title={tab?.title}
                                        disabled={tab?.disabled}>
                                        {tab?.children}
                                    </Tab>)}
                        </Tabs>
                    </div>)
            case 'tab-with-border-badge':
                return (
                    <div className='tab-with-border gap-3 mt-3 d-flex flex-column'>
                        <Tabs defaultActiveKey="profile"
                            id="tab-with-border"
                            activeKey={selected}
                            onSelect={(selected) => onChange(selected)}
                            className="mb-2">
                            {data?.length &&
                                data?.map((tab, index) =>
                                    <Tab eventKey={tab?.title}
                                        key={index}
                                        title={<span className='d-inline-flex align-items-center gap-2'><span>{tab.icon}</span> <span>{tab?.title}</span></span>}
                                        disabled={tab?.disabled}>
                                        {tab?.children}
                                    </Tab>)}
                        </Tabs>
                    </div>)
            default:
                return (<Tabs
                    defaultActiveKey="profile"
                    id="default-tab"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3">
                    {data?.length &&
                        data?.map((tab, index) =>
                            <Tab eventKey={tab?.title}
                                key={index}
                                title={tab?.title}
                                disabled={tab?.disabled}>
                                {tab?.children}
                            </Tab>)}
                </Tabs>)
        }
    }



    return (
        <div className='tabs'>
            {renderTabs()}
        </div>
    )
}

export default CustomTabs