import React from 'react'
import { Card } from 'react-bootstrap'
import "./index.scss"

export const CustomerCard = ({ variant, children, className, bodyClass }) => {

    const renderCards = () => {
        switch (variant) {
            case 'customer-detail-card':

                return (<Card className={`customer-details-card ${className}`}>
                <Card.Body>
                    {children}
                </Card.Body>
            </Card>)

            case 'sales-users-details-card':
                return (<Card className={`sales-admin-details-card ${className}`}>
                        <Card.Body>
                            {children}
                        </Card.Body>
                </Card>)
            case 'sub-admin-details-card':
                return (<Card  className='sub-admin-details-card'>
                        <Card.Body>
                            {children}
                        </Card.Body>
                    </Card>)
            case 'dashboard-head-card':
                return (<Card  className='dashboard-head-card'>
                        <Card.Body>
                            {children}
                        </Card.Body>
                    </Card>)

            default:
                return (<Card className={className}>
                    <Card.Body className={bodyClass}>
                        {children}
                    </Card.Body>
                </Card>)
        }
    }

    return (
        <div className='customer-card'>{renderCards()}</div>
    )
}

