import React, { useState } from 'react'
import { Dropdown, DropdownButton, Form } from 'react-bootstrap'
import { Avatar, Typography } from '../../components'
import "./index.scss"

export const DropDown = ({ variant, options, chooseOptions, placeholder, label, icon, handleDropDown, activeIndex,
    CustomComponent, onToggle, isOpen, customClass, onSelectImage, openSelectedImage }) => {
    const [selected, setSelected] = useState("")

    const renderDropdownComponent = () => {
        switch (variant) {
            case 'button-dropdown':
                return (
                    <div className='button-dropdown'>
                        <Dropdown className='button-dropdown' >
                            <Dropdown.Toggle variant="secondary-gray" className={customClass} id="dropdown-basic">
                                {icon ? icon : ''}
                                {label}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {options?.length > 0 &&
                                    options.map((option, i) =>
                                        <Dropdown.Item onClick={() => handleDropDown(option)} key={option.id} className={i === activeIndex ? 'active' : ''} >{option.label}</Dropdown.Item>)}
                                {CustomComponent && CustomComponent}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )
            case 'button-dropdown-with-option-icon':
                return (<Dropdown className='button-dropdown-with-option-icon' onSelect={handleDropDown} show={isOpen} onToggle={onToggle}>
                    <Dropdown.Toggle id={label} className="toggle-button">
                        {icon} {label}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {options?.length > 0 &&
                            options.map((option, i) =>
                                <Dropdown.Item active={activeIndex === i} key={option.id} eventKey={option?.value}><option.icon /> {option?.label}</Dropdown.Item>)}
                        {CustomComponent && <CustomComponent />}
                    </Dropdown.Menu>
                </Dropdown>)

            case 'quick-promotions-image-select':
                return (<Dropdown show={isOpen} onToggle={onToggle}>
                    <Dropdown.Toggle className='quick-promotions-image-select' id="dropdown-basic">
                        {icon}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={customClass}>
                        {CustomComponent && <CustomComponent />}
                        {options?.length > 0 &&
                            options.map((option, i) =>
                                <div className='d-flex align-items-center gap-2 dropdown-item' eventKey={option?.value} key={option.id} >
                                    <div className='d-block'>
                                        {option?.images?.length > 0 ?
                                            <Avatar onClick={() => openSelectedImage(option)} src={option?.images?.[0]?.src} errorImage="/images/noimage.svg" variant={"quick-promotion"} customClass={`size-${option?.size.replace(' ', '')}`} />
                                            : <Form.Group controlId="formFileSm" className={`quick-promo-list-upload size-${option?.size.replace(' ', '')}`}>
                                                <Form.Control type="file" name={option.id} onChange={onSelectImage} />
                                            </Form.Group>}
                                        {/* size-9x4 size-8x10 size-10x6 size-7x3 size-9x10 */}
                                    </div>
                                    <div className='d-flex flex-column text-wrap text-break'>
                                        <Typography variant="text-custom" fontSize={'text-13 lh-16'} fontWeight={'font-semibold'} margin={'m-0'} color={'color-gray'} label={option?.label} />
                                        <Typography variant="text-custom" fontSize={'text-12 lh-16'} fontWeight={'font-semibold'} margin={'m-0'} color={'color-info'} label={option?.size} />
                                    </div>
                                </div>)}

                    </Dropdown.Menu>
                </Dropdown>)

            case 'select-item':
                return (
                    <div className='default'>
                        <DropdownButton
                            variant="outline-secondary"
                            onSelect={handleDropDown}
                            className={customClass}
                            title={chooseOptions ? chooseOptions : placeholder}
                            id="input-group-dropdown-1">
                            {options?.length > 0 &&
                                options?.map((option) =>
                                    <div key={option.id}>
                                        <div className='d-flex align-items-center gap-1 menu-options'>
                                            <Dropdown.Item eventKey={option.value}>{option?.label}</Dropdown.Item>
                                        </div>
                                    </div>)}
                        </DropdownButton>
                    </div>)
            default:
                return (
                    <div className='default'>
                        <DropdownButton
                            variant="outline-secondary"
                            className={customClass}
                            title={selected || placeholder}
                            id="input-group-dropdown-1">

                            {options?.length > 0 &&
                                options?.map((option) =>
                                    <div key={option.id}>
                                        <div className='d-flex align-items-center gap-1 menu-options'
                                            onClick={() => { chooseOptions(placeholder, option); setSelected(option.label) }}>
                                            <Dropdown.Item >{option?.label}</Dropdown.Item>
                                        </div>
                                    </div>)}
                        </DropdownButton>
                    </div>
                )
        }

    }

    return (
        <div className='DropDown'>
            {renderDropdownComponent()}

        </div>
    )
}
