import {
    CREATE_PROMOTION_BEGINS,
    CREATE_PROMOTION_FAILURE,
    CREATE_PROMOTION_SUCCESS,
    DELETE_PROMOTION_BEGINS,
    DELETE_PROMOTION_FAILURE,
    DELETE_PROMOTION_SUCCESS,
    GET_FILTER_LIST_BEGINS,
    GET_FILTER_LIST_FAILURE,
    GET_FILTER_LIST_SUCCESS,
    GET_PROMOTIONS_DETAILS_BEGINS, GET_PROMOTIONS_DETAILS_FAILURE, GET_PROMOTIONS_DETAILS_SUCCESS,
    GET_PROMOTIONS_LIST_BEGINS,
    GET_PROMOTIONS_LIST_FAILURE, GET_PROMOTIONS_LIST_SUCCESS, MANAGE_QUICK_PROMOTIONS_LIST_BEGINS, MANAGE_QUICK_PROMOTIONS_LIST_FAILURE, MANAGE_QUICK_PROMOTIONS_LIST_SUCCESS, UPDATE_PROMOTION_BEGINS, UPDATE_PROMOTION_FAILURE, UPDATE_PROMOTION_SUCCESS
} from "../constants"

const INITIAL_STATE = {
    promotions: {
        data: [],
        total: 0
    },
    promotionsLoader: false,
    promotionDetails: {},
    promotionDetailsLoader: false,
    filters: [],
    filtersLoader: false,
    createPromotionLoader: false,
    quickPromotions: [],
    promotionLoader: false,
   
}

const promotionManagementReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PROMOTIONS_LIST_BEGINS:
            return { ...state, promotionsLoader: true }
        case GET_PROMOTIONS_LIST_SUCCESS:
            return { ...state, promotions: action?.data, promotionsLoader: false }
        case GET_PROMOTIONS_LIST_FAILURE:
            return { ...state, promotionsLoader: false }

        case GET_PROMOTIONS_DETAILS_BEGINS:
            return { ...state, promotionDetailsLoader: true }
        case GET_PROMOTIONS_DETAILS_SUCCESS:
            return { ...state, promotionDetails: action?.data, promotionDetailsLoader: false }
        case GET_PROMOTIONS_DETAILS_FAILURE:
            return { ...state, promotionDetailsLoader: false }

        case GET_FILTER_LIST_BEGINS:
            return { ...state, filtersLoader: true }
        case GET_FILTER_LIST_SUCCESS:
            return { ...state, filters: action?.data, filtersLoader: false }
        case GET_FILTER_LIST_FAILURE:
            return { ...state, filtersLoader: false }

        case CREATE_PROMOTION_BEGINS:
            return { ...state, createPromotionLoader: true }
        case CREATE_PROMOTION_SUCCESS:
            return {
                ...state, createPromotionLoader: false,
                promotions: {
                    data: [...[action?.data], ...state?.promotions?.data || []],
                    total: state.promotions.total + 1
                }
            }
        case CREATE_PROMOTION_FAILURE:
            return { ...state, createPromotionLoader: false }

        case UPDATE_PROMOTION_BEGINS:
            return { ...state, createPromotionLoader: true }
        case UPDATE_PROMOTION_SUCCESS:
            const { id, resData } = action?.data;
            const selectedIndex = state.promotions.data.findIndex(item => Number(item.id) === Number(id));
            const newPromotions = [...state.promotions.data];
            newPromotions[selectedIndex] = { ...newPromotions[selectedIndex], ...resData };
            return { ...state, promotions: { ...state.promotions, data: newPromotions }, createPromotionLoader: false }
        case UPDATE_PROMOTION_FAILURE:
            return { ...state, createPromotionLoader: false }

        case MANAGE_QUICK_PROMOTIONS_LIST_BEGINS:
            return { ...state, promotionLoader: true }
        case MANAGE_QUICK_PROMOTIONS_LIST_SUCCESS:
            return { ...state, promotionLoader: false, quickPromotions: action?.data }
        case MANAGE_QUICK_PROMOTIONS_LIST_FAILURE:
            return { ...state, promotionLoader: false }

        case DELETE_PROMOTION_BEGINS:
            return { ...state, promotionDetailsLoader: true }
        case DELETE_PROMOTION_SUCCESS:
            const { deleteId } = action?.data;
            const newPromotionsList = state.promotions.data.filter(item => Number(item.id) !== Number(deleteId));
            return { ...state, promotions: { ...state.promotions, data: newPromotionsList }, promotionDetailsLoader: false }
        case DELETE_PROMOTION_FAILURE:
            return { ...state, promotionDetailsLoader: false }

        default:
            return state;
    }
}
export default promotionManagementReducer;
