import React, {  useState } from 'react'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button } from '..';
import "./index.scss";


export const ImageCropper = ({ src, ratio, croppedImage, handleCloseDrawer }) => {

    const [image, setImage] = useState(src);
    const [cropper, setCropper] = useState();
    const [cropped, setcropped] = useState(false)

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setImage(cropper.getCroppedCanvas().toDataURL());
            setcropped(true);
        }
    };

    const handleSubmit = () => croppedImage(image);


    return (<div className='d-flex flex-column gap-2 jusify-content-center'>
        {cropped ? <img src={image} style={{ width: "100%" }} alt={`cropped -${image}`} />
            : <Cropper
                style={{ height: 400, width: "100%", objectFit: 'contain' }}
                zoomTo={0}
                aspectRatio={ratio?.split('x')[0]?.trim() / ratio?.split('x')[1]?.trim()}
                preview=".img-preview"
                src={image}
                dragMode="move"
                viewMode={1}
                scalable ={false}
                background={false}
                responsive={true}
                onInitialized={(instance) => {
                    setCropper(instance);
                }}
                guides={true}
            />}
        <div className='d-flex gap-2 w-100 justify-content-end flex-wrap mt-2'>
            <Button label="Cancel" onClick={handleCloseDrawer} customClass={'width-sm'} variant="secondary-shadow" />
            {cropped ?
                <Button label="Submit" variant={'primary-shadow'} customClass={'width-sm'} onClick={handleSubmit} />
                : <Button label="Crop" variant={'primary-shadow'} customClass={'width-sm'} onClick={getCropData} />}
        </div>
    </div>
    )
}

