import React from 'react'
import "./index.scss"

export const Typography = ({ variant, label, color, infoSymbol, margin, textAlign, sublabel, subcolor, iconCustom, fontSize, fontWeight, subSize,sublabel2, submargin }) => {

    const renderTypographyComponent = () => {
        switch (variant) {
            case 'title':
                return <p className={`font-secondary text-24 ${color || ''} ${margin || ''}`}>{label}</p>
            case 'subtitle':
                return <p className={`text-24 font-semibold ${color || ''} ${margin || ''}`}>{label}<span className='color-primary'>{sublabel}</span></p>
            
            case 'subtitle-v1':
                return <p className={`text-18 font-bold ${color || ''} ${margin || ''}`}>{label} <span className='text-20 color-primary'>{sublabel}</span></p>

            case 'welcomeTitle':
                return <p className={`text-35 font-bold ${color || ''} ${margin || ''}`}><span className='text-18 font-semibold'>{label}</span><br/>{sublabel}</p>
                
            case 'heading1':
                return <h1 className={`text-34 font-bold ${color || ''} ${margin || ''}`}>{label}</h1>

            case 'heading2':
                return <h2 className={`text-24 font-bold ${color || ''} ${margin || ''}`}>{label}</h2>

            case 'heading3':
                return <h3 className={`text-18 font-bold ${color || ''} ${margin || ''}`}>{label}</h3>

            case 'heading4':
                return <h4 className={`text-18 font-semibold ${color || ''} ${margin || ''}`}>{label}</h4>

            case 'heading5':
                return <h5 className={`text-16 font-bold ${color || ''} ${margin || ''}`}>{label}</h5>

            case 'heading6':
                return <h6 className={`text-14 font-bold ${color || ''} ${margin || ''}`}>{label}</h6>

            case 'subtitle1':
                return <p className={`text-17 font-semibold ${color || ''} ${margin || ''}`}>{label} {sublabel ?  <span className='color-primary'>{sublabel}</span> :''}</p>

            case 'subtitle2':
                return <p className={`text-14 font-semibold ${color || ''} ${margin || ''}`}>{label}</p>
            case 'subtitle3':
                return <p className={`text-15 font-semibold ${color || ''} ${margin || ''}`}>{label}</p>

            case 'body1':
                return <p className={`text-16 font-medium ${color || ''} ${margin || ''}`}>{label} {infoSymbol ?  <span className='color-info mx-2'>{infoSymbol}</span> :''} {sublabel}</p>

            case 'body2':
                return <p className={`text-15 font-medium ${color || ''} ${margin || ''}`}>{label} {infoSymbol ?  <span className='color-info mx-2'>{infoSymbol}</span> :''} {sublabel}</p>
            
            case 'body3':
                return <p className={`text-14 font-medium ${color || ''} ${margin || ''}`}>{label} {infoSymbol ?  <span className='color-info mx-2'>{infoSymbol}</span> :''} {sublabel}</p>

            case 'body4':
                return <p className={`text-13 font-medium ${color || ''} ${margin || ''}`}>{label} {infoSymbol ?  <span className='color-info mx-2'>{infoSymbol}</span> :''} {sublabel}</p>

            case 'placeholder':
                return <p className={`text-13 font-semibold ${color || ''} ${margin || ''}`}>{label} {infoSymbol ?  <span className='color-info'>{infoSymbol}</span> :''} {sublabel}</p>

            case 'price01':
                return <p className={`text-16 font-semibold color-primary ${color || ''} ${margin || ''}`}>{label}</p>
            
            case 'copyright':
                return <p className={`text-11 m-0 ${color || ''} ${margin || ''}`}>{label}</p>

            case 'text-custom':
                return <p className={`${fontSize || ''} ${fontWeight || ''} ${color || ''} ${margin || ''}`}>{label}{sublabel ?  <span className={`${subcolor || 'color-info'}`}>{sublabel}</span> :''}</p>

            case 'text-custom-multilabel':
                return <p className={`${fontSize || ''} ${fontWeight || ''} ${color || ''} ${margin || ''} ${textAlign || ''}`}>{label}{sublabel ?  <span className={`${subcolor || 'color-info'} ${submargin || 'mx-1'}`}>{sublabel}</span> :''}{sublabel2 ?  <span>{sublabel2}</span> :''}</p>

            case 'dynamic-text':
                return <p className={`${fontSize || ''} ${fontWeight || ''} ${color || ''} ${margin || ''}`}>{label} {infoSymbol ?  <span className={`${iconCustom || 'color-info mx-2'}`}>{infoSymbol}</span> :''} {sublabel ?  <span className={`${subcolor || ''} ${subSize || ''}`}>{sublabel}</span> :''}</p>

            case 'heading-custom':
                return <h5 className={`${fontSize || ''} ${fontWeight || ''} ${color || ''} ${margin || ''}`}>{label}</h5>

            default:
                return <p>{label}</p>
        }
    }


    return (
        <div className='typography'>
            {renderTypographyComponent()}
        </div>
    )
}

