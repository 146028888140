import React, { useState } from 'react';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

export const CustomDatePicker = ({ variant, currentDate, handleChange, minDate }) => {
    const [date, setDate] = useState(currentDate ? new Date(currentDate) : null);

    const handleCalendarClose = () => console.log("Calendar closed");
    const handleCalendarOpen = () => console.log("Calendar opened");

    const handleChangeDate = (date) => {
        setDate(date);
        handleChange(date);
    }

    const renderDatePicker = () => {
        switch (variant) {
            case 'single-select':
                return (
                    <DatePicker
                        minDate={minDate}
                        selected={date}
                        placeholderText="Select date"
                        onChange={(date) => handleChangeDate(date)}
                    />
                )

            default:
                return (<DatePicker
                    selected={date}
                    onChange={(date) => handleChangeDate(date)}
                    onCalendarClose={handleCalendarClose}
                    onCalendarOpen={handleCalendarOpen}
                />)
        }

    }
    return (
        <div>{renderDatePicker()}</div>
    )
}
