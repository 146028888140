import React, { useState } from 'react'
import Image from 'react-bootstrap/Image'
import "./index.scss"

export const Avatars = ({ variant, membership, src, rounded, fluid, roundedCircle, thumbnail, bsPrefix, errorImage, customClass, parentClass,onClick }) => {

    const [imageurl, setImage] = useState(src || errorImage || "")

    const onError = () => {
        setImage(errorImage)
    }

    const renderComponents = () => {
        switch (variant) {
            case "profile-image":
                return <div membership={membership}> <Image alt={src}  className='profile-image' onError={onError} src={src} rounded={rounded} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /> </div>
            case "profile-image-no-border":
                return <div membership={membership} className='profile-image-no-border'> <Image alt={src}  className='profile-image' onError={onError} src={src} rounded={rounded} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /> </div>
            case "profile-image-md":
                return <div className='profile-image-md' membership={membership}> <Image alt={src}  onError={onError} className='profile-image' src={src} rounded={rounded} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /> </div>
            case "header-pro-thumb":
                return <div className={`${parentClass ? parentClass : ''}`}><Image className={`header-pro-thumb ${customClass ? customClass : ''}`} src={src} onError={onError} rounded={rounded} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>
                // return <div className={`${parentClass ? parentClass : ''}`}><Image className={`header-pro-thumb ${customClass ? customClass : ''}`} src={imageurl} onError={onError} rounded={rounded} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>
            case "logo-login":
                return <Image  alt={imageurl} className='logo-login' src={imageurl} fluid={fluid} onError={onError} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            case "table-product-image":
                return <Image  alt={imageurl} className='table-product-image' src={imageurl} onError={onError} fluid={fluid} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            case "order-image":
                return <Image  alt={imageurl} className='order-image' src={imageurl} onError={onError} fluid={fluid} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            case "order-detail-thumb":
                return <Image  alt={imageurl} className='order-detail-thumb' src={imageurl} onError={onError} fluid={fluid} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            case "sidebar-logo":
                return <Image  alt={imageurl} className='sidebar-logo' src={imageurl} fluid={fluid} onError={onError} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            case "gif-loader":
                return <Image alt={imageurl}  className='gif-loader' src={imageurl} fluid={fluid} onError={onError} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            case "product-details":
                return <Image alt={imageurl}  className='product-details' src={imageurl} fluid={fluid} onError={onError} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            case "product-details-small-items":
                return <Image alt={imageurl}  className='product-details-small-items' src={imageurl} fluid={fluid} onError={onError} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            case "promotion-detail":
                return <Image  alt={imageurl} className='promotion-detail' src={imageurl} fluid={fluid} onError={onError} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            case "quick-promotion":
                return <Image  alt={imageurl} onClick={onClick} className={`quick-promotion-list ${customClass ? customClass : ''}`} src={imageurl} onError={onError} fluid={fluid} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            default:
                return <Image  alt={imageurl} src={imageurl} rounded={rounded} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} />
        }
    }

    return (
        <div className='avatars'>
            {renderComponents()}
        </div>
    )
}
