import React from 'react'
import { Modal } from 'react-bootstrap';
import { CloseModalIcon } from '../SVGIcons';
import "./index.scss";


function CustomModal(props) {
    const { children, handleclosemodal, size, className, customPadding } = props;
    const customPaddingClass = customPadding ? 'custom-padding' : '';
    return (

        <Modal {...props} size={`${size ? size : 'lg'}`}
            className={className ? `${className} custom-modal ${customPaddingClass}` : `custom-modal ${customPaddingClass}`}
            aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Title id="contained-modal-title-vcenter">
                <CloseModalIcon onClick={handleclosemodal} />
            </Modal.Title>
            <Modal.Body>
                {children}
            </Modal.Body>

        </Modal>

    );
}


const CustomDialogue = ({ children, isOpen, handleClose, size, className, customPadding }) => {
    const handleclosemodal = () => handleClose();
    return (
        <>
            <CustomModal
                show={isOpen}
                size={size}
                handleclosemodal={handleclosemodal}
                children={children}
                className={className}
                customPadding={customPadding}
            />
        </>
    )
}

export default CustomDialogue