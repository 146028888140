import React from 'react'
import './index.scss'
import { Button } from '..'
import { Link } from 'react-router-dom'

export const NotFound = () => {
  return (
    <div className='not-found'>
      <h1>
        404
      </h1>
      <h2>
        Page not found
      </h2>
      <Link to="/">
        <Button label={"Go back home"} />
      </Link>
    </div>
  )
}
