import React from 'react';
import { Badge } from 'react-bootstrap';
import { Buttons } from '../Buttons';
import { FilterClose } from '../SVGIcons';
import "./index.scss";

export const Chips = ({ options, handleClose, clearAll, customClass }) => {
    return (
        <div className={`chips ${customClass ? customClass : ''}`}>
            {options?.length > 0 &&
                options?.map((option,i) =>
                    <Badge key={i} bg="white">
                        <span>{option?.label}</span>
                        <FilterClose onClick={()=>handleClose(i)} />
                    </Badge>)}

                    {options?.length > 0 &&<Buttons onClick={clearAll} label="Clear all" variant="link-info" />}
        </div>
    )
}
