import {
	LOGIN_BEGINS, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT_BEGINS, LOGOUT_FAILURE, RESET_PASSWORD_BEGINS,
	RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, LOGOUT_SUCCESS, SEND_FORGOT_PASSWORD_BEGINS, SEND_FORGOT_PASSWORD_FAILURE, SEND_FORGOT_PASSWORD_SUCCESS, GET_USER_DATA_WITH_AUTH_BEGINS, GET_USER_DATA_WITH_AUTH_SUCCESS, GET_USER_DATA_WITH_AUTH_FAILURE, SEND_OTP_BEGINS, SEND_OTP_SUCCESS, SEND_OTP_FAILURE
} from "../constants";

const INITIAL_STATE = {
	isLoading: false,
	user: { ...JSON.parse(localStorage.getItem('userData')) } || { email: '', profileImage: '', username: '', usertype: '' },
	error: '',
	isAuthenticated: (localStorage.getItem('loggedIn') && JSON.parse(localStorage.getItem('loggedIn') || '')?.isloggedIn) || false,
	userToken: (localStorage.getItem('loggedIn') && JSON.parse(localStorage.getItem('loggedIn') || '')?.token) || '',
	OTPKey: { key: '', email: '', first_login: '' },
};

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOGIN_BEGINS:
			return { ...state, isLoading: true }
		case LOGIN_SUCCESS:
			return {
				state, isLoading: false, isAuthenticated: true,
				userToken: action.data.token,
				OTPKey: { ...state.OTPKey, email: action?.data?.email, first_login: action?.data?.first_login }
			}
		case LOGIN_FAILURE:
			return { ...state, isLoading: false, }
		case LOGOUT_BEGINS:
			return { ...state, isLoading: true }
		case LOGOUT_SUCCESS:
			return { ...state, isLoading: false, user: {}, isAuthenticated: false, userToken: '' }
		case LOGOUT_FAILURE:
			return { ...state, isLoading: false }
		case SEND_FORGOT_PASSWORD_BEGINS:
			return { ...state, isLoading: true, OTPKey: { key: '', email: '' } }
		case SEND_FORGOT_PASSWORD_SUCCESS:
			return { ...state, isLoading: false, OTPKey: { key: action.data.key, email: action.data.email } }
		case SEND_FORGOT_PASSWORD_FAILURE:
			return { ...state, isLoading: false, OTPKey: { key: '', email: '' } }
		case RESET_PASSWORD_BEGINS:
			return { ...state, isLoading: true }
		case RESET_PASSWORD_SUCCESS:
			return { ...state, isLoading: false }
		case RESET_PASSWORD_FAILURE:
			return { ...state, isLoading: false }
		case SEND_OTP_BEGINS:
			return { ...state, isLoading: true }
		case SEND_OTP_SUCCESS:
			return { ...state, isLoading: false }
		case SEND_OTP_FAILURE:
			return { ...state, isLoading: false }
		case GET_USER_DATA_WITH_AUTH_BEGINS:
			return { ...state, isLoading: true }
		case GET_USER_DATA_WITH_AUTH_SUCCESS:
			return { ...state, isLoading: false, user: action.data }
		case GET_USER_DATA_WITH_AUTH_FAILURE:
			return { ...state, isLoading: false }

		default:
			return state;
	}
};

export default authReducer;