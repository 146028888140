import React, { useEffect, useRef, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import Placeholder from 'react-bootstrap/Placeholder'
import { Badge } from 'react-bootstrap';
import { Button, FilterWithSuggestion, Search, DropDown } from '..';
import { ArrowLeftIcon, ArrowRighttIcon, CaretLeftIcon, CaretRightIcon, FilterClose, FilterIcon } from '../SVGIcons';
import { SnackMessages } from '../Toast';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "./index.scss"
import { Buttons } from '../Buttons';


const CustomTable = (props) => {
    const { headers, data, loader, fetchData, totalSize, CustomRightSideComponent,CustomLeftSideComponent, filterComponent, enableSearch,
        pageProps, customFIlterChanges, ExpandedRow, getSearchUserByName, suggestionList, suggestionLoader, updateTextSearch,filterButton,
        clearsuggestionsList, setExpanded, selectedProductFilter } = props;

    const parentScroll = useRef(null);
    const childScroll = useRef(null);

    const [filter, setfilter] = useState(false)
    const [filterCount, setFilterCount] = useState()
    const [parameters, setParameters] = useState([])
    const [menuIsOpen, setMenuOpen] = useState(false)

    const { showInfoMessage } = SnackMessages();

    let timeout = 0;



    const pageButtonRenderer = (props) => {
        const { page, active, disabled, onPageChange } = props;

        const handlePaginationClick = (e) => {
            e.preventDefault();
            const newPage = page === '>' ? pageProps?.page + 1 : page === '<' && page.props !== 0 ? pageProps?.page - 1 : page === '<<' ? pageProps?.page - 10 : page === '>>' ? pageProps?.page + 10 : page;
            onPageChange(newPage);

        };

        return (
            <li key={page} className={`page-item ${active ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
                <button onClick={handlePaginationClick} >
                    {isNaN(page) && page === ">" ? <ArrowRighttIcon /> : page === "<" ? <ArrowLeftIcon /> : page === 0 ? 1 : typeof page === "number" ? page + 1 : page }
                </button>
            </li>
        );
    };

    const customTotal = (from, to, size) => (
            <span className="text-13 font-semibold color-gray02 m-0">
                Showing page {data?.length ?
                    <>{pageProps?.page === 0 ? 1 : ` ${( Number(pageProps?.page) + 1 || 1)}`} of {Math.ceil(totalSize / pageProps?.limit) || totalSize}</> :
                    <> 0 of 0</>}
            </span>
        )
    

    const handleSearch = (e) => {
        let { value } = e.target
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            fetchData({ ...pageProps, search: value, page: 0 })
        }, 1000);
    }

    const handleFilterClick = () => {
        setfilter(!filter)
    }


    const SearchFilterComponent = () => {
        let newHeaders = [...headers]
        newHeaders = filter && newHeaders?.length ? newHeaders?.filter((item) => item.filterable) : [];
        const chooseFilterDropDownOptions = (option, values) => {
            const after = option.substring(option.indexOf('Select') + 7);
            const searchParam = { name: after, value: values?.label?.toLowerCase() }
            const filtered = parameters?.length && parameters.filter(option => option.value?.toLowerCase() === values.label?.toLowerCase())
            const sum = !filtered?.length > 0 && parameters.concat(searchParam)
            if (sum?.length) {
                setParameters(sum);
                customFIlterChanges(sum)
            } else {
                showInfoMessage(`${values.label} already selected`)
            }

        }



        const handleSearchFilter = (option, values) => {
            const after = option.substring(option.indexOf('Search') + 10);

            if (Number(values?.isChecked) === 1) {
                const searchParam = { name: after, id: values.id, value: values.customerName?.toLowerCase() }
                const isUnique = parameters?.length && parameters.filter(option => option.value?.toLowerCase() === values.customerName?.toLowerCase())
                const sum = !isUnique?.length > 0 && parameters.concat(searchParam)
                if (sum?.length) {
                    setParameters([...sum])
                    customFIlterChanges(sum)
                }
            }
            else if (Number(values?.isChecked) === 0) {
                let newParams = [...parameters]
                newParams = newParams.filter(option => option.value?.toLowerCase() !== values.customerName?.toLowerCase())
                setParameters([...newParams])
            }
        }

        const getOptions = (value) => {

            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                getSearchUserByName(value?.toLowerCase())
            }, 1000);
        }

        // const handleSearchTextField = (e, type) => {
        //     if (timeout) clearTimeout(timeout);
        //     timeout = setTimeout(() => {
        //         const { value } = e.target
        //         const searchParam = { name: value, id: value, value: value }
        //         const isUnique = parameters?.length && parameters.filter(option => option.value?.toLowerCase() === value)
        //         const sum = !isUnique?.length > 0 && parameters.concat(searchParam)
        //         if (sum?.length) {
        //             setParameters([...sum])
        //             customFIlterChanges(sum)
        //             updateTextSearch(value, type)
        //         } else showInfoMessage(`${value} already selected`);
        //     }, 1000);
        // }

        // const sliderParentWidth = parentScroll.current?.offsetWidth || null;
        // const sliderChildWidth = childScroll.current?.clientWidth || null;


        const scroll = (scrollOffset) => (parentScroll.current.scrollLeft += scrollOffset);

        return (
            <div className="filter-slider d-flex align-items-center gap-2">
                {filter && newHeaders?.length > 3 && <div className='d-flex flex-column justify-content-center slider-btn'>
                    <Buttons onClick={() => scroll(-200)} icon={<CaretLeftIcon />} customClass="p-1" variant="secondary-gray" />
                </div>}
                <div className="search-filter-container d-flex gap-2" ref={parentScroll}>
                    <div className="d-flex gap-2 search-filter-scrollable w-fit-content" ref={childScroll} >
                        {newHeaders?.length > 0 &&
                            newHeaders?.map((item, idx) =>
                                <div key={idx} className='filter-container'>
                                    <span className='filter-title'>
                                        {item.text === "blockCode" ? "" : item.text}
                                    </span>
                                    {item.fieldType === 'text-suggestion' ? <FilterWithSuggestion menuIsOpen={menuIsOpen} getSearchUserByName={getSearchUserByName}
                                        setMenuOpen={setMenuOpen} clearsuggestionsList={clearsuggestionsList} pageProps={pageProps} list={suggestionList} getOptions={getOptions} loader={suggestionLoader} value={parameters} handleSearchFilter={handleSearchFilter} placeholder={`Search by ${item?.text}`} />
                                        : item.fieldType === 'select' ? <DropDown chooseOptions={chooseFilterDropDownOptions} placeholder={`Select ${item?.text}`} options={item?.options} />
                                            // : item.fieldType === 'text-field' ? <Search handleSearchBar={(e) => handleSearchTextField(e, item.text)} placeholder={`Search ${item?.text}`} />
                                                : ''}
                                </div>
                            )}
                    </div>
                </div>
                {filter && newHeaders?.length > 3 && <div className='d-flex flex-column justify-content-center slider-btn'>
                    <Buttons onClick={() => scroll(200)} icon={<CaretRightIcon />} customClass="p-1" variant="secondary-gray" />
                </div>}
            </div>
        )
    }

    useEffect(() => {
        let newHeaders = [...headers]
        newHeaders = newHeaders?.length ? newHeaders?.filter((item) => item.filterable) : []
        setFilterCount(newHeaders?.length)

    }, [headers, filterCount])


    const SearchBadges = () => {

        const removeBadge = (idx) => {
            let newparameterArray = [...parameters]
            newparameterArray = newparameterArray.filter(((item, i) => i !== idx))
            setParameters(newparameterArray)
            customFIlterChanges(newparameterArray)

        }

        const handleClearSearchSwitches = () => {
            setParameters([])
            customFIlterChanges([])
            getSearchUserByName('')
        }

        return (<div className='badges'>
            {parameters?.length ? <>
            {parameters?.length > 0 &&
                parameters?.map((search, idx) => <Badge bg="white" key={idx}>
                    <FilterClose onClick={() => removeBadge(idx)} />
                    {search?.value || ''}
                </Badge>)}

            {parameters?.length > 0 && <Button onClick={handleClearSearchSwitches} label="Clear all" variant="link-info" />}
            </> : 
            <>
            {selectedProductFilter?.length > 0 &&
                selectedProductFilter?.map((search, idx) => <Badge bg="white" key={idx}>
                    <FilterClose onClick={() => removeBadge(idx)} />
                    {search?.value || ''}
                </Badge>)}

            {selectedProductFilter?.length > 0 && <Button onClick={handleClearSearchSwitches} label="Clear all" variant="link-info" />}
            </>}
        </div>)
    }


    const expandRow = {
        renderer: (row, rowIndex) => (<ExpandedRow row={row} index={rowIndex} />),
        showExpandColumn: true,
        expandColumnRenderer: ({ expanded }) => setExpanded(expanded)
    };

    const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

        (totalSize / pageProps?.limit) > page && page > -1 && fetchData({ ...pageProps, page: page, limit: sizePerPage, sort: { sortBy: sortField || pageProps?.sort?.sortBy, sortOrder: sortOrder || pageProps?.sort?.sortOrder } })
    }

    const paginationOption = {
        sizePerPage: pageProps?.limit,
        alwaysShowAllBtns: true,
        firstPageText: "First",
        lastPageText: "Last",
        pageButtonRenderer: pageButtonRenderer,
        paginationTotalRenderer: customTotal,
        pageStartIndex: 0,
        hideSizePerPage: true,
        paginationSize: 5,
        withFirstAndLast: true,
        totalSize: totalSize,
        page: pageProps?.page,
        showTotal: totalSize && totalSize > 0 ? true : false,
        dataSize: data?.length,
        disablePageTitle: true,
        hidePageListOnlyOnePage: true,

    }

    return (
        <div className='table'>
            {<ToolkitProvider
                keyField={'id' || 'key'}
                // remote
                data={!loader && data?.length ? data : []}
                columns={headers}
                search>
                {(props) => (<>

                    {props && <div className='custom-table'>
                        {enableSearch && <div className='table-header'>
                            <Search placeholder={`Search ${pageProps?.name}`} value={pageProps?.search} handleSearchBar={(e) => handleSearch(e)} />
                            <div className='d-flex align-items-center gap-3 flex-wrap justify-content-end'>
                                {CustomLeftSideComponent && <CustomLeftSideComponent />}
                                {filterButton && <Button label="Filters" variant="secondary-gray" count={parameters?.length} onClick={handleFilterClick} icon={<FilterIcon />} />}
                                {CustomRightSideComponent && <CustomRightSideComponent />}
                            </div>
                        </div>}
                        {filterComponent && <div className='table-body'>
                            <SearchFilterComponent />
                        </div>}
                        <div className='search-badges'>
                            <SearchBadges />
                        </div>
                       
                        <BootstrapTable
                            remote
                            
                            onTableChange={onTableChange}
                            expandRow={ExpandedRow && expandRow}
                            noDataIndication={() => (loader ? <CustomLoader headers={headers} /> : <p className='text-center text-18 color-info m-0 py-lg-3'>No data available</p>)}
                            filter={filterFactory()}
                            {...props.baseProps}
                            pagination={paginationFactory(paginationOption)}
                        />
                    </div>}
                </>)}
            </ToolkitProvider>}
        </div>)
}

const CustomLoader = ({ headers }) =>  (
        <span className='d-flex flex-column gap-2'>
            {Array.from(Array(10).keys())?.map((load) => <div key={load} className='d-flex gap-3'>
                {headers.length &&
                    headers?.filter(e => !e?.hidden)?.map((colHead, subId) =>
                        <Placeholder as="p" animation='glow' key={subId} style={{ width: '100%', height: 30 }} >
                            <Placeholder as="p" style={{ width: '100%', height: 30 }} ></Placeholder>
                        </Placeholder>)}
            </div>)}
        </span>
    )




export default React.memo(CustomTable)