import React from 'react'
import { Button, Typography } from '../..'
import { WishlistMenuIcon } from '../../SVGIcons'
import "./index.scss"

const DialogueChildren = () => {
  return (
    <div className='dialogue-children'>
      <div className='dialogue-title'>

        <Typography variant={'subtitle'} label="Do you Want to Remove, " sublabel="Fossil M color" />
        <Typography variant={'subtitle'} label=" From your Wish List ?" />
      </div>
      <div className='dialogue-buttons'>
        <Button label="No, Thanks!" variant="secondary-shadow" />
        <Button label="Yes, Remove it!" icon={<WishlistMenuIcon />} variant="primary-shadow" />
      </div>
    </div>)
}
export default DialogueChildren