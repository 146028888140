import React from 'react'
import { Form } from 'react-bootstrap'
import "./index.scss"

export const CustomRadio = ({ label, checked, onChange, name, customClass }) => {
 
    return (
        <div className={`custom-radio ${customClass ? customClass : ''}`}>
            <Form.Check
                type={'radio'}
                onChange={onChange}
                checked={checked}
                name={name}
                label={label}
            />
        </div>
    )
}
