import { GET_MY_PROFILE_BEGINS, GET_MY_PROFILE_FAILURE, GET_MY_PROFILE_SUCCESS } from "../constants";

const INITIAL_STATE = {
	isLoading: false,
	profile: {}
};

const profileReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case GET_MY_PROFILE_BEGINS:
			return { ...state, isLoading: true }
		case GET_MY_PROFILE_SUCCESS:
			return { ...state, isLoading: false, profile: action.data }
		case GET_MY_PROFILE_FAILURE:
			return { ...state, isLoading: false }

		default:
			return state;
	}
};

export default profileReducer;