import React from 'react'
import Card from "react-bootstrap/Card";
import './index.scss';

export const Cards = ({ variant, children, className ,customClass}) => {

    const renderCardComponent = () => {
        switch (variant) {
            case 'off-white-card':
                return (
                    <Card className={customClass ? customClass : ''}>
                        <Card.Body>{children}</Card.Body>
                    </Card>)

            case 'login-card':
                return (
                    <Card className='login-card'>
                        <Card.Body>{children}</Card.Body>
                    </Card>)
            case 'promotion-card':
                return (
                    <Card className='promotion-card'>
                              <Card.Body>{children}</Card.Body>
                    </Card>)

             case 'chat-card':
                return (
                    <Card className={className}>
                        <Card.Body>{children}</Card.Body>
                    </Card>)


            default:
                return (

                    <Card>
                        <Card.Body>{children}</Card.Body>
                    </Card>)
        }
    }

    return (
        <div className='cards'>
            {renderCardComponent()}

        </div>
    )
}
