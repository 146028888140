import { GET_DASHBOARD_BEGINS, GET_DASHBOARD_FAILURE, GET_DASHBOARD_SUCCESS, UPDATE_APP_BAR_TITLE, UPDATE_APP_BAR_TITLE_BEGINS } from "../constants";

const INITIAL_STATE = {
	isLoading: false,
	appBarTitle: "DashBoard",
	loadAppBarTitle: false,
	error: '',
	dashboard: {}
};

const dashBoardReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case UPDATE_APP_BAR_TITLE_BEGINS:
			return { ...state, isLoading: true }
		case UPDATE_APP_BAR_TITLE:
			return { ...state,  isLoading: false };

		case GET_DASHBOARD_BEGINS:
			return { ...state, isLoading: true }
		case GET_DASHBOARD_SUCCESS:
			return { ...state, isLoading: false, dashboard: action.data }
		case GET_DASHBOARD_FAILURE:
			return { ...state, isLoading: false }


		default:
			return state;
	}
};

export default dashBoardReducer;