import React from 'react'
import Rating from "react-rating";
import { StarIconEmpty, StarIconFull } from '../SVGIcons';

export const CustomRatings = ({ rating }) => {
    return (
        <div>
            <Rating
                emptySymbol={<StarIconEmpty />}
                fullSymbol={<StarIconFull />}
                readonly
                initialRating={Number(rating)}
                fractions={2}
            />
        </div>
    )
}
