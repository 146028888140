import React from 'react'
import { Offcanvas } from 'react-bootstrap';
// import { Button } from '../../components'
import "./index.scss"

export const Drawer = ({ children, handleCloseDrawer, data, title }) => {

    const OffCanvasExample = ({ name, ...props }) => {

        const handleClose = () => handleCloseDrawer();

        return (
            <>
                <Offcanvas keyboard={true}
                    bsPrefix="offcanvas"
                    renderStaticNode={true}
                    enforceFocus={true} backdrop={true} show={data.isOpen} onHide={handleClose} {...props}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{title || ''}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {children || ''}
                    </Offcanvas.Body>

                </Offcanvas>
            </>
        );
    }


    return (
        <div className='drawer'>
            <OffCanvasExample placement={data.position} name={data.position} />
        </div>
    )
}
