import React, { useEffect, useState } from 'react'
import Downshift from 'downshift'
import { useDispatch } from 'react-redux'

import { Placeholder } from 'react-bootstrap'
import CustomCheckbox from '../Checkbox'
import { FilterClose } from '../SVGIcons'
import './index.scss';
import {  actionSearchUserByName } from '../../actions/Dashboard/UserManagement'
import {SnackMessages } from '../../components'

export const FilterWithSuggestion = ({
    placeholder,  handleSearchFilter, clearsuggestionsList }) => {
        const dispatch = useDispatch();

    const [menuList, setMenuList] = useState([])
    const [menuIsOpen, setMenuOpen] = useState(false)
    let timeout = 0;
    const { showErrorMessage, showSuccessMessage } = SnackMessages();

    const [list, setsalesUsersList] = useState([])

    const [listLoader, setListLoader] = useState(false);

    const fetchStyles = (item) => item?.isChecked ? 'active' : ''
    const getOptions = (value) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            getSearchUserByName(value?.toLowerCase())
        }, 1000);
    }


    const getSearchUserByName = async (searchText) => {
        const payload = {
            search: searchText,
            role_id: 3,
        }
        const response = await dispatch(actionSearchUserByName(payload))
        if (!response.success) {
            showErrorMessage(response.message)
        } else {
            setsalesUsersList(response.response)
        }
    }

    useEffect(() => {
        if (menuList?.length) {
            setMenuOpen(true)
        }
    }, [menuList, setMenuOpen])

    useEffect(() => {
        if (JSON.parse(JSON.stringify(list)) !== JSON.parse(JSON.stringify(menuList))) {
            setMenuList(list)

            setListLoader(false)
        }
    }, [menuList, list])


    const handleChooseItem = (element) => {
        let newList = [...menuList]
        const index = newList.findIndex(item => item?.customerName === element?.customerName)
        newList[index].isChecked = newList[index]?.isChecked === 0 ? 1 : 0
        setMenuList(newList)
        handleSearchFilter(placeholder, element)
    }

    const handleCloseList = () => {
        setMenuList([]);
        setMenuOpen(false)
        clearsuggestionsList()

    }

    const onInputValueChange = (selection) => {
       
         if (selection !== undefined) {
            setMenuOpen(true)
            setListLoader(true)
            getOptions(selection)
         }
    }


    return (
        <div className={'search-select'} >
            <Downshift
            defaultInputValue={'inputValue'}
                onOuterClick={handleCloseList}
                isOpen={menuIsOpen}
                onInputValueChange={onInputValueChange}
                itemToString={item => (item ? item.name?.toLowerCase() : '')}>
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    inputValue,
                    isOpen
                }) => (
                    <div className='search-filter'>

                        <input {...getInputProps()} value={inputValue} placeholder={placeholder} className="form-control" />
                        {<>
                            {listLoader ? <CustomLoader /> : <ul {...getMenuProps()}>
                                {isOpen && <>
                                    {menuList?.length > 0
                                        ? menuList.filter(item => !inputValue || item.customerName?.toLowerCase().includes(inputValue.toLowerCase()))
                                            .map((item, index) => (
                                                <li
                                                    onClick={() => handleChooseItem(item)}
                                                    {...getItemProps({
                                                        key: item.customerName,
                                                        index,
                                                        item,
                                                    })}
                                                    className={`search-list ${fetchStyles(item)}`}>
                                                    <CustomCheckbox variant="labelled"
                                                        checked={Boolean(item?.isChecked)}
                                                        label={item?.customerName}
                                                        onChange={() => handleChooseItem(item)} />

                                                </li>
                                            )) :
                                        (<div className='user-not-found'>
                                            <p>No User found</p>
                                        </div>)
                                    }

                                </>}

                            </ul>}
                            {isOpen && <div className='suggestion-close '>
                                <FilterClose onClick={handleCloseList} />
                            </div>}

                        </>}
                    </div>
                )}
            </Downshift>
        </div>
    )
}

const CustomLoader = () => {
    return (
        <div className=' gap-2 custom-loader'>
            {Array.from(Array(4).keys()).map(load => (
                <Placeholder key={load} animation="glow">
                    <Placeholder className="w-100 p-3 gap-2 mt-2" />
                </Placeholder>
            ))}

        </div>
    )

}