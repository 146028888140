import {
    GET_ASSIGN_PRODUCTS_LIST_BEGINS,
    GET_ASSIGN_PRODUCTS_LIST_FAILURE,
    GET_ASSIGN_PRODUCTS_LIST_SUCCESS,
    GET_CUSTOMER_CART_LIST_BEGINS,
    GET_CUSTOMER_CART_LIST_FAILURE,
    GET_CUSTOMER_CART_LIST_SUCCESS,
    GET_CUSTOMER_DETAILS_BY_ID_BEGINS,
    GET_CUSTOMER_DETAILS_BY_ID_FAILURE,
    GET_CUSTOMER_DETAILS_BY_ID_SUCCESS,
    GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_BEGINS,
    GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_FAILURE,
    GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_SUCCESS,
    GET_CUSTOMER_LIST_BEGINS, GET_CUSTOMER_LIST_FAILURE, GET_CUSTOMER_LIST_SUCCESS, GET_CUSTOMER_OFFERS_LIST_BEGINS, GET_CUSTOMER_OFFERS_LIST_FAILURE, GET_CUSTOMER_OFFERS_LIST_SUCCESS, GET_CUSTOMER_ORDER_LIST_BEGINS, GET_CUSTOMER_ORDER_LIST_FAILURE, GET_CUSTOMER_ORDER_LIST_SUCCESS, GET_CUSTOMER_WISHLIST_BEGINS, GET_CUSTOMER_WISHLIST_FAILURE, GET_CUSTOMER_WISHLIST_SUCCESS, GET_ORDER_RATINGS_AND_FEEDBACK_BEGINS, GET_ORDER_RATINGS_AND_FEEDBACK_FAILURE, GET_ORDER_RATINGS_AND_FEEDBACK_SUCCESS, GET_SALES_USERS_BEGINS,
    GET_SALES_USERS_FAILURE, GET_SALES_USERS_SUCCESS, GET_SALES_USER_DETAILS_BY_ID_BEGINS, GET_SALES_USER_DETAILS_BY_ID_FAILURE, GET_SALES_USER_DETAILS_BY_ID_SUCCESS, GET_SEARATES_DETAILS_BEGINS, GET_SEARATES_DETAILS_FAILURE, GET_SEARATES_DETAILS_SUCCESS, GET_SUBADMINS_BEGINS, GET_SUBADMINS_FAILURE, GET_SUBADMINS_SUCCESS, GET_SUB_ADMIN_DETAILS_BEGINS, GET_SUB_ADMIN_DETAILS_FAILURE, GET_SUB_ADMIN_DETAILS_SUCCESS, GET_SUB_USERS_LIST_BEGINS, GET_SUB_USERS_LIST_FAILURE, GET_SUB_USERS_LIST_SUCCESS, GET_WISHLISTS_BEGINS, GET_WISHLISTS_FAILURE, GET_WISHLISTS_SUCCESS, UPDATE_CUSTOMERS_SUCCESS, UPDATE_SALES_USER_SUCCESS, UPDATE_SUBADMIN_SUCCESS, UPDATE_USER_STATUS_BEGINS, UPDATE_USER_STATUS_FAILURE, UPDATE_USER_STATUS_SUCCESS,
    GET_CUSTOMER_SALES_USERS_SUCCESS

} from "../constants";


const INITIAL_STATE = {
    drawerLoader: false,
    isTableLoading: false,
    slaesUserLoader: false,
    customers: {
        data: [],
        total: 0
    },
    salseUsers: {
        data: [],
        total: 0
    },
    customerSalesUsers: [],
    subAdmins: {
        data: [],
        total: 0
    },
    subUsers: {
        data: [],
        total: 0
    },
    offers: {
        data: [],
        total: 0
    },
    cartProducts: {
        data: [],
        total: 0
    },
    ratingandFeedBacks: {
        data: [],
        total: 0
    },
    customerWishlist: {
        data: [],
        total: 0
    },
    interestedProducts: {
        data: [],
        total: 0
    },
    assignProducts: {
        data: [],
        total: 0
    },
    isSearatesLoading: false,
    customerSuggestions: [],
    salesUsersList: [],
    customersList: [],
    isLoadCustomerSuggesion: false,
    searchSuggestion: "",
    wishLists: {
        data: [],
        total: 0
    },
    customerDetails: {},
    isLoadCustomerDetails: false,
    subAdminDetails: {},
    isLoadSubAdminDetails: false,
    salesUserDetails: {},
    isLoadSalesUserDetails: false

};

const updatewithNewStates = (oldArray, newObj) => {

    let newArray = oldArray.data.map(item => {
        if (item.id === newObj.id) {
            return { ...item, ...newObj };
        }
        return item;
    });
    return {
        data: newArray,
        total: oldArray.total
    }

}


const userManagementReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CUSTOMER_LIST_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_CUSTOMER_LIST_SUCCESS:
            return { ...state, isTableLoading: false, customers: action.data }
        case GET_CUSTOMER_LIST_FAILURE:
            return { ...state, isTableLoading: false }

        case UPDATE_CUSTOMERS_SUCCESS:
            return { ...state, customers: updatewithNewStates(state.customers, action.data) }

        case GET_SALES_USERS_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_SALES_USERS_SUCCESS:
            return { ...state, isTableLoading: false, salseUsers: action.data }

        case GET_CUSTOMER_SALES_USERS_SUCCESS:
            return { ...state, isTableLoading: false, customerSalesUsers: action.data }

        case GET_SALES_USERS_FAILURE:
            return { ...state, isTableLoading: false }

        case UPDATE_SALES_USER_SUCCESS:
            return { ...state, salseUsers: updatewithNewStates(state.salseUsers, { ...action.data, id: action?.data?.salesUserId }) }

        case GET_SUBADMINS_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_SUBADMINS_SUCCESS:
            return { ...state, isTableLoading: false, subAdmins: action.data }
        case GET_SUBADMINS_FAILURE:
            return { ...state, isTableLoading: false }

        case UPDATE_SUBADMIN_SUCCESS:
            return { ...state, isTableLoading: false, subAdmins: updatewithNewStates(state.subAdmins, { ...action.data, permission: action?.data?.permissions?.join() }) }
        case UPDATE_USER_STATUS_BEGINS:
            return { ...state, isTableLoading: true }
        case UPDATE_USER_STATUS_SUCCESS:
            return {
                ...state, isTableLoading: false,
                salseUsers: action?.data?.role_id === 3 && updatewithNewStates(state?.salseUsers, { ...action?.data, is_active: action?.data?.isActive ? 1 : 0 }),
                subAdmins: action?.data?.role_id === 2 && updatewithNewStates(state?.subAdmins, { ...action?.data, status: action?.data?.isActive ? "Active" : "In-Active" }),
            }
        case UPDATE_USER_STATUS_FAILURE:
            return { ...state, isTableLoading: false }

        case GET_WISHLISTS_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_WISHLISTS_SUCCESS:
            return { ...state, isTableLoading: false, wishLists: action.data }
        case GET_WISHLISTS_FAILURE:
            return { ...state, isTableLoading: false }

        case GET_CUSTOMER_DETAILS_BY_ID_BEGINS:
            return { ...state, isLoadCustomerDetails: true }
        case GET_CUSTOMER_DETAILS_BY_ID_SUCCESS:
            return { ...state, isLoadCustomerDetails: false, customerDetails: { ...action.data } }
        case GET_CUSTOMER_DETAILS_BY_ID_FAILURE:
            return { ...state, isLoadCustomerDetails: false }

        case GET_CUSTOMER_CART_LIST_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_CUSTOMER_CART_LIST_SUCCESS:
            return { ...state, isTableLoading: false, cartProducts: action.data }
        case GET_CUSTOMER_CART_LIST_FAILURE:
            return { ...state, isTableLoading: false }

        case GET_SUB_USERS_LIST_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_SUB_USERS_LIST_SUCCESS:
            return { ...state, isTableLoading: false, subUsers: action.data }
        case GET_SUB_USERS_LIST_FAILURE:
            return { ...state, isTableLoading: false }

        case GET_CUSTOMER_OFFERS_LIST_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_CUSTOMER_OFFERS_LIST_SUCCESS:
            return { ...state, isTableLoading: false, offers: action.data }
        case GET_CUSTOMER_OFFERS_LIST_FAILURE:
            return { ...state, isTableLoading: false }

        case GET_ORDER_RATINGS_AND_FEEDBACK_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_ORDER_RATINGS_AND_FEEDBACK_SUCCESS:
            return { ...state, isTableLoading: false, ratingandFeedBacks: action.data }
        case GET_ORDER_RATINGS_AND_FEEDBACK_FAILURE:
            return { ...state, isTableLoading: false }

        case GET_SUB_ADMIN_DETAILS_BEGINS:
            return { ...state, isLoadSubAdminDetails: true }
        case GET_SUB_ADMIN_DETAILS_SUCCESS:
            return { ...state, isLoadSubAdminDetails: false, subAdminDetails: { ...action.data } }
        case GET_SUB_ADMIN_DETAILS_FAILURE:
            return { ...state, isLoadSubAdminDetails: false }

        case GET_SALES_USER_DETAILS_BY_ID_BEGINS:
            return { ...state, isLoadSalesUserDetails: true }
        case GET_SALES_USER_DETAILS_BY_ID_SUCCESS:
            return { ...state, isLoadSalesUserDetails: false, salesUserDetails: action.data }
        case GET_SALES_USER_DETAILS_BY_ID_FAILURE:
            return { ...state, isLoadSalesUserDetails: false }

        case GET_CUSTOMER_WISHLIST_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_CUSTOMER_WISHLIST_SUCCESS:
            return { ...state, isTableLoading: false, customerWishlist: action.data }
        case GET_CUSTOMER_WISHLIST_FAILURE:
            return { ...state, isTableLoading: false }

        case GET_CUSTOMER_ORDER_LIST_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_CUSTOMER_ORDER_LIST_SUCCESS:
            return { ...state, isTableLoading: false, customerOrder: action.data }
        case GET_CUSTOMER_ORDER_LIST_FAILURE:
            return { ...state, isTableLoading: false }

        case GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_SUCCESS:
            return { ...state, isTableLoading: false, interestedProducts: action.data }
        case GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_FAILURE:
            return { ...state, isTableLoading: false }

        case GET_ASSIGN_PRODUCTS_LIST_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_ASSIGN_PRODUCTS_LIST_SUCCESS:
            return { ...state, isTableLoading: false, assignProducts: action.data }
        case GET_ASSIGN_PRODUCTS_LIST_FAILURE:
            return { ...state, isTableLoading: false }

        default:
            return state;
    }
};

export default userManagementReducer;