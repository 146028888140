import {
    GET_ADDRESS_LIST_BEGINS, GET_ADDRESS_LIST_FAILURE, GET_ADDRESS_LIST_SUCCESS,
    GET_DELIVERED_ORDER_DETAILS_BY_ID_BEGINS,
    GET_DELIVERED_ORDER_DETAILS_BY_ID_FAILURE,
    GET_DELIVERED_ORDER_DETAILS_BY_ID_SUCCESS,
    GET_OPEN_ORDER_DETAILS_BY_ID_BEGINS, GET_OPEN_ORDER_DETAILS_BY_ID_FAILURE, GET_OPEN_ORDER_DETAILS_BY_ID_SUCCESS,
    GET_ORDERS_BEGINS, GET_ORDERS_FAILURE, GET_ORDERS_SUCCESS, GET_ORDER_DETAILS_BY_ID_BEGINS,
    GET_ORDER_DETAILS_BY_ID_FAILURE, GET_ORDER_DETAILS_BY_ID_SUCCESS, GET_REQUEST_ORDER_DETAILS_BY_ID_BEGINS,
    GET_REQUEST_ORDER_DETAILS_BY_ID_FAILURE, GET_REQUEST_ORDER_DETAILS_BY_ID_SUCCESS, GET_SEARATES_DETAILS_BEGINS, GET_SEARATES_DETAILS_FAILURE, GET_SEARATES_DETAILS_SUCCESS, GET_UNIT_LIST_BEGINS,
    GET_UNIT_LIST_FAILURE, GET_UNIT_LIST_SUCCESS
} from "../constants";

const INITIAL_STATE = {
    isTableLoading: false,
    orders: {
        data: [],
        total: 0
    },
    isOpenOrderDetailsLoading: false,
    openOrderDetails: {
        feedback: [],
        invoice: {},
        orderSummary: [],
        shippingAddress: [],
        shippingDetails: {},
    },
    isDeliveredOrderDetailsLoading: false,
    deliveredOrderDetails: {
        feedback: [],
        invoice: {},
        orderSummary: [],
        shippingAddress: [],
        shippingDetails: {},
    },
    isOrderDetailsLoading: false,
    orderDetails: {
        OrderDetails: []
    },
    isRequestOrderDetailsLoading: false,
    requestOrderDetails: {
        orderSummary: [],
        address: [],
        offers: [],
        shippingDetails: [],
        invoice: {},
        wishList: [],
        cart: [],
        feedback: []
    },
    isAddressLoading: false,
    address: {
        data: [],
        total: 0
    },
    unitLists: [],
    isUnitLoading: false,
    searates: {},
    isSearatesDetailsLoading: false
};



const orderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ORDERS_BEGINS:
            return { ...state, isTableLoading: true }
        case GET_ORDERS_SUCCESS:
            return { ...state, isTableLoading: false, orders: action.data }
        case GET_ORDERS_FAILURE:
            return { ...state, isTableLoading: false }

        case GET_OPEN_ORDER_DETAILS_BY_ID_BEGINS:
            return { ...state, isOpenOrderDetailsLoading: true }
        case GET_OPEN_ORDER_DETAILS_BY_ID_SUCCESS:
            return { ...state, isOpenOrderDetailsLoading: false, openOrderDetails: action?.data }
        case GET_OPEN_ORDER_DETAILS_BY_ID_FAILURE:
            return { ...state, isOpenOrderDetailsLoading: false }

        case GET_ORDER_DETAILS_BY_ID_BEGINS:
            return { ...state, isOrderDetailsLoading: true }
        case GET_ORDER_DETAILS_BY_ID_SUCCESS:
            return { ...state, isOrderDetailsLoading: false, orderDetails: action?.data }
        case GET_ORDER_DETAILS_BY_ID_FAILURE:
            return { ...state, isOrderDetailsLoading: false }

        case GET_REQUEST_ORDER_DETAILS_BY_ID_BEGINS:
            return { ...state, isRequestOrderDetailsLoading: true }
        case GET_REQUEST_ORDER_DETAILS_BY_ID_SUCCESS:
            return { ...state, isRequestOrderDetailsLoading: false, requestOrderDetails: action?.data }
        case GET_REQUEST_ORDER_DETAILS_BY_ID_FAILURE:
            return { ...state, isRequestOrderDetailsLoading: false }

        case GET_ADDRESS_LIST_BEGINS:
            return { ...state, isAddressLoading: true }
        case GET_ADDRESS_LIST_SUCCESS:
            return { ...state, isAddressLoading: false, address: action.data }
        case GET_ADDRESS_LIST_FAILURE:
            return { ...state, isAddressLoading: false }

        case GET_UNIT_LIST_BEGINS:
            return { ...state, isUnitLoading: true };
        case GET_UNIT_LIST_SUCCESS:
            return { ...state, isUnitLoading: false, unitLists: action.data };
        case GET_UNIT_LIST_FAILURE:
            return { ...state, isUnitLoading: false };

        case GET_DELIVERED_ORDER_DETAILS_BY_ID_BEGINS:
            return { ...state, isDeliveredOrderDetailsLoading: true };
        case GET_DELIVERED_ORDER_DETAILS_BY_ID_SUCCESS:
            return { ...state, isDeliveredOrderDetailsLoading: false, deliveredOrderDetails: action?.data };
        case GET_DELIVERED_ORDER_DETAILS_BY_ID_FAILURE:
            return { ...state, isDeliveredOrderDetailsLoading: false };

            case GET_SEARATES_DETAILS_BEGINS:
                return { ...state, isSearatesDetailsLoading: true };
            case GET_SEARATES_DETAILS_SUCCESS:
                return { ...state, isSearatesDetailsLoading: false, searates: action?.data };
            case GET_SEARATES_DETAILS_FAILURE:
                return { ...state, isSearatesDetailsLoading: false };

        default:
            return state;
    }
};

export default orderReducer;