import React, { Suspense } from 'react'
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from "react-router-dom";
import { AppBar } from '../../components';
import { GlobalSpinner } from '../../components/Spinner';
import Chat from './Chat';
import "./index.scss";


const Sidebar = React.lazy(() => import('../../components/Sidebar'));
const DashBoardScreen = React.lazy(() => import('./DashboardScreen'));
const Products = React.lazy(() => import('./Products'));
const ProductDetails = React.lazy(() => import('./Products/Details'));
const OrderDetails = React.lazy(() => import('./Orders/Details'));
const Orders = React.lazy(() => import('./Orders'));
const Customers = React.lazy(() => import('./UserManagement/Customers'));
const CustomerDetails = React.lazy(() => import('./UserManagement/Customers/Details'));
const SalesUsers = React.lazy(() => import('./UserManagement/SalesUsers'));
const SalesUserDetails = React.lazy(() => import('./UserManagement/SalesUsers/Details'));
const SubAdmins = React.lazy(() => import('./UserManagement/SubAdmins'));
const SubAdminDetails = React.lazy(() => import('./UserManagement/SubAdmins/Details'));
const PromotionManagement = React.lazy(() => import('./PromotionManagement'));
const OfferManagement = React.lazy(() => import('./OfferManagement'));
const OfferDetails = React.lazy(() => import('./UserManagement/Customers/Details/Tabs/Offers/OfferDetails'));
const OrderConfirm = React.lazy(() => import('./Orders/ConfirmPage'));
const MembershipManagement = React.lazy(() => import('./MembershipManagement'));
const MyProfile = React.lazy(() => import('./Profile'));
const Story = React.lazy(() => import('./Story'));


export const DashBoard =  React.memo((props) => {
    const { isAuthenticated } = useSelector((state) => state?.authReducer)

    const PrivateRoute = ({ children }) => {
        return isAuthenticated ? children : <Navigate to="/auth/login" />
    }


    return (
        <Suspense fallback={<GlobalSpinner />}>
            <div className='dashboard'>
                <Sidebar />
                <div className='main'>
                    <AppBar />
                    <Routes>
                        <Route path="/dashboard" element={<PrivateRoute ><DashBoardScreen /></PrivateRoute>} />
                        <Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>} />
                        <Route path="/products/:id/details" element={<PrivateRoute><ProductDetails /></PrivateRoute>} />
                        <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
                        <Route path="/orders/:id/details" element={<PrivateRoute><OrderDetails /></PrivateRoute>} />
                        <Route path="/customers" element={<PrivateRoute><Customers /> </PrivateRoute>} />
                        <Route path="/customer/:id/details" element={<PrivateRoute><CustomerDetails /> </PrivateRoute>} />
                        <Route path="/customer/offer/:id/details" element={<PrivateRoute><OfferDetails /> </PrivateRoute>} />
                        <Route path="/sales-users" element={<PrivateRoute> <SalesUsers /></PrivateRoute>} />
                        <Route path="/sales-users/:id/details" element={<PrivateRoute> <SalesUserDetails /></PrivateRoute>} />
                        <Route path="/sub-admins" element={<PrivateRoute><SubAdmins /> </PrivateRoute>} />
                        <Route path="/sub-admins/:id/details" element={<PrivateRoute><SubAdminDetails /> </PrivateRoute>} />
                        <Route path="/promotion-management" element={<PrivateRoute><PromotionManagement /> </PrivateRoute>} />
                        <Route path="/offer-management" element={<PrivateRoute><OfferManagement /> </PrivateRoute>} />
                        <Route path="/confirm-order" element={<PrivateRoute><OrderConfirm /> </PrivateRoute>} />
                        <Route path="/membership-management" element={<PrivateRoute><MembershipManagement /> </PrivateRoute>} />
                        <Route path="/story" element={<PrivateRoute><Story /> </PrivateRoute>} />
                        <Route path="/chats" element={<PrivateRoute><Chat /> </PrivateRoute>} />
                        <Route path="/dashboard/profile" element={<PrivateRoute><MyProfile /> </PrivateRoute>} />
                        <Route path="*" element={<Navigate to={"/404"} />} />
                    </Routes>
           
            </div>
            </div>
        </Suspense>
    )
})

