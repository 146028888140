import React, { useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import { DashBoardMenuIcon, ProductMenuIcon, OrdersMenuIcon, UserManagementMenuIcon, PromotionManagementMenuIcon, OfferManagementMenuIcon, MembershipMenuIcon, NotificationMenuIcon, ChatMenuIcon, LogOutMenuIcon, EmailAtIcon, FormLockIcon, EyeOpenIcon, EyeCloseIcon, ImportUserIcon, SuccessTickIcon, CheckTickIcon, ChatIcon, ProfileIcon, SettingsIcon, LogoutIcon, EmailIcon, PhoneIcon, LocationIcon, EditPencilIcon, CloseModalIcon, MenuCloseIcon, NotificationIcon, CartIcon, CaretRightIcon, SearchIcon, DimensionIcon, ThicknessIcon, FilterIcon, SortIcon, WishlistIcon, TableViewIcon, TableMoreIcon, DeactivateIcon, TableSort, TableSortDown, TableSortUp, BackIcon, StarIconEmpty, StarIconHalf, StarIconFull, PlusIcon, APISyncIcon,MostPurchased, CaretLeftIcon, SolidCartIcon, SolidHeartIcon, SolidInterestedIcon, PriceTagIcon, QuantityIcon, TrashIcon, DescriptionSuccessIcon, EditLocationIcon, ShareIcon, ApproveIcon, DownloadIcon, ShippingIcon, ProcessingIcon, PendingIcon, DeliveredIcon, TrashSmIcon, CrossXIcon, CaptureIcon, CloseCircleIcon } from '../../components/SVGIcons'
import { Avatar, Button, Dialogue, DialogueChildren, Drawer, Search, Tabs, Typography } from '../../components'
import { GlobalSpinner } from '../../components/Spinner'
import { SnackMessages } from '../../components/Toast';
import CustomCheckbox from '../../components/Checkbox'


export default function AllComponents() {
    const { showWarnMessage, showSuccessMessage, showErrorMessage, showInfoMessage, showToastMessage } = SnackMessages();
    const [isOpenModal, setOpenModal] = useState(false)
    const [leftDrwawer, setLeftDrawer] = useState({
        isOpen: false,
        position: 'end',
        data: {}
    })

    const handleCloseDrawer = () => {
        setLeftDrawer({
            ...leftDrwawer,
            isOpen: false,
            data: {}

        })
    }
    return (
        <div className='container py-3 bg-body'>
            <h1 className='m-0 mb-3'>All Components</h1>

            {/* Buttons */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex justify-content-between align-items-center py-3'>
                    <h3 className='m-0'>Buttons</h3>
                    <Button label="Filter" variant="secondary-gray" icon={<FilterIcon />} />
                </div>
                <div className='card-body'>
                    <div className='d-flex flex-row flex-wrap align-items-center gap-3 py-3'>
                        <Button label="Primary" variant="primary" />
                        <Button label="Continue" variant="btn-primary-lg" />
                        <Button label="Connect with TAB" variant="btn-primary-md" />
                        <Button label="Continue" disabled variant="btn-primary-lg" />
                        <Button label="Forgot Password?" variant="link-info" />
                        <Button label="Filter" variant="link-secondary" />
                        <Button label="View all" variant="btn-link" />
                        <Button label="Cancel" variant="secondary" />
                        <Button label="Invite User" icon={<ImportUserIcon />} pos="right" variant="secondary-shadow" />
                        <Button label="Add to Cart" icon={<CartIcon />} variant="primary-shadow" />
                        <Button label="Select Quantity" variant="info" />
                        <Button icon={<ChatIcon />} variant="primary-rounded" />
                        <Button icon={<ChatIcon />} variant="secondary-rounded" />
                        <Button icon={<CartIcon />} count="4" variant="white-rounded" />
                        <Button icon={<NotificationIcon />} count="4" variant="white-rounded" />
                        <Button label="Edit" icon={<EditPencilIcon />} variant="outline-primary" />
                        <Button label="Add Product" icon={<PlusIcon />} variant="outline-secondary" customClass={'btn-sm'} />

                        <Button icon={<TableViewIcon />} variant="outline-white" />
                        <Button icon={<TableMoreIcon />} variant="outline-gray" />
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-3'>
                    <div className='mx-2'>
                        <Button label="Cancel" variant="secondary-shadow" />
                    </div>
                    <div className='mx-2'>
                        <Button label="Update" variant="primary-shadow" />
                    </div>
                </div>
            </div>

            {/* Icons */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Icons</h3>
                </div>
                <div className='card-body'>
                    <div className='row gx-2 m-0'>
                        {/* Form Icons */}
                        <div className='col-12 col-md-6 rounded-3'>
                            <div className='row flex-column card bg-light m-0 mb-4'>
                                <div className='card-header d-flex'>
                                    <h5 className='m-0'>Form Icons</h5>
                                </div>
                                <div className='card-body'>
                                    <div className='d-flex flex-row flex-wrap gap-2 align-items-center'>
                                        <EmailAtIcon />     {/* @ symbol in Text field */}
                                        <FormLockIcon />    {/* Lock icon in Password Field */}
                                        <EyeOpenIcon />     {/* Opened Eye icon in Password Field */}
                                        <EyeCloseIcon />    {/* Closed Eye icon in Password Field */}
                                        <SearchIcon />      {/* Seacrh Icon */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Side Menu Icons */}
                        <div className='col-12 col-md-6 rounded-3'>
                            <div className='row flex-column card bg-light m-0 mb-4'>
                                <div className='card-header d-flex'>
                                    <h5 className='m-0'>Menu Icons</h5>
                                </div>
                                <div className='card-body bg-secondary'>
                                    <div className='d-flex flex-row flex-wrap gap-2 align-items-center'>
                                        <DashBoardMenuIcon />           {/* DashBoard */}
                                        <ProductMenuIcon />             {/* Product */}
                                        <OrdersMenuIcon />              {/* Orders */}
                                        <UserManagementMenuIcon />      {/* User Management */}
                                        <PromotionManagementMenuIcon /> {/* Promotion Management */}
                                        <OfferManagementMenuIcon />     {/* Offer Management */}
                                        <MembershipMenuIcon />          {/* Membership Management */}
                                        <NotificationMenuIcon />        {/* Notification */}
                                        <ChatMenuIcon />                {/* Chat */}
                                        <LogOutMenuIcon />              {/* LogOut */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Button Icons */}
                        <div className='col-12 col-md-6 rounded-3'>
                            <div className='row flex-column card bg-light m-0 mb-4'>
                                <div className='card-header d-flex'>
                                    <h5 className='m-0'>Button Icons</h5>
                                </div>
                                <div className='card-body' style={{ background: "#F1F1F1" }}>
                                    <div className='d-flex flex-row flex-wrap gap-2 align-items-center'>
                                        <ImportUserIcon />  {/* Add User Icon */}
                                        <ChatIcon />        {/* Chat Icon */}
                                        <EditPencilIcon />  {/* Edit Pencil Icon */}
                                        <NotificationIcon />{/* Notification Icon */}
                                        <CartIcon />        {/* Cart Icon */}
                                        <CaretLeftIcon />  {/* Caret Left Icon */}
                                        <CaretRightIcon />  {/* Caret Right Icon */}
                                        <FilterIcon />      {/* Filter Icon */}
                                        <SortIcon />        {/* Sort Icon */}
                                        <WishlistIcon />    {/* Wishlist Icon */}
                                        <TableViewIcon />   {/* Table View Icon */}
                                        <TableMoreIcon />   {/* Table More Icon */}
                                        <DeactivateIcon />  {/* Deactivate Icon */}
                                        <BackIcon />        {/* Back Icon */}
                                        <PlusIcon />        {/* Plus Icon */}
                                        <APISyncIcon />     {/* API Sync Icon */}
                                        <MostPurchased />   {/* Most Purchased */}
                                        <WishlistIcon />    {/* Wishlist Icon */}
                                        <SolidCartIcon />   {/* Solid Cart Icon */}
                                        <SolidHeartIcon />  {/* Solid Heart Icon */}
                                        <SolidInterestedIcon /> {/* Solid Interested Icon */}
                                        <TrashIcon />       {/* Trash Icon */}
                                        <TrashSmIcon />       {/* Trash Small Icon */}
                                        <DescriptionSuccessIcon />  {/* Description Icon */}
                                        <EditLocationIcon />    {/* Edit Location Icon */}
                                        <ShareIcon />           {/* Share Icon */}
                                        <ApproveIcon />         {/* Approve Icon */}
                                        <DownloadIcon />        {/* Download Icon */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Other Icons */}
                        <div className='col-12 col-md-6 rounded-3'>
                            <div className='row flex-column card bg-light m-0 mb-4'>
                                <div className='card-header d-flex'>
                                    <h5 className='m-0'>Other Icons</h5>
                                </div>
                                <div className='card-body' style={{ background: "#F1F1F1" }}>
                                    <div className='d-flex flex-row flex-wrap gap-2 align-items-center'>
                                        <SuccessTickIcon /> {/* Success Message Icon */}
                                        <CheckTickIcon />   {/* Checkbox Tick Icon */}
                                        <CrossXIcon />   {/* Cross X Icon */}
                                        <ProfileIcon />     {/* Dropdown Profile Icon */}
                                        <SettingsIcon />    {/* Dropdown Settings Icon */}
                                        <LogoutIcon />      {/* Dropdown Logout Icon */}
                                        <EmailIcon />       {/* Email Icon */}
                                        <PhoneIcon />       {/* Phone Icon */}
                                        <LocationIcon />    {/* Location Icon */}
                                        <CloseModalIcon />  {/* Close Modal Icon*/}
                                        <MenuCloseIcon />   {/* Close Menu Icon*/}
                                        <DimensionIcon />   {/* Dimension Icon*/}
                                        <ThicknessIcon />   {/* Thickness Icon*/}
                                        <TableSort />       {/* Table Sort */}
                                        <TableSortDown />   {/* Table Sort Down */}
                                        <TableSortUp />     {/* Table Sort Up */}

                                        <StarIconEmpty/>    {/* Start Rating Empty */}
                                        <StarIconHalf/>     {/* Start Rating Half */}
                                        <StarIconFull/>     {/* Start Rating Full */}

                                        <PriceTagIcon/>     {/* Pric tag Icon */}
                                        <QuantityIcon/>     {/* Quantity Icon */}

                                        <ShippingIcon/>     {/* Shipping Icon */}
                                        <ProcessingIcon/>   {/* Processing Icon */}
                                        <PendingIcon/>      {/* Pending Icon */}
                                        <DeliveredIcon/>    {/* Delivered Icon */}

                                        <CaptureIcon/>      {/* Capture Camera Icon */}
                                        <CloseCircleIcon/>  {/* Close Circle Icon */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Avatars */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Avatars</h3>
                </div>
                <div className='card-body'>
                    <div className='d-flex flex-row flex-wrap align-items-center gap-3 py-3'>
                        <Avatar bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image'} membership={'silver'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image'} membership={'gold'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image'} membership={'platinum'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image-md'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image-md'} membership={'silver'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image-md'} membership={'gold'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image-md'} membership={'platinum'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'header-pro-thumb'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar bsPrefix src="/images/badge-silver.svg" />
                        <Avatar bsPrefix src="/images/badge-gold.svg" />
                        <Avatar bsPrefix src="/images/badge-platinum.svg" />
                        <Avatar variant={'table-product-image'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                    </div>
                </div>
            </div>

            {/* Typography */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Typography</h3>
                </div>
                <div className='card-body text-start'>
                    <Typography variant={'heading1'} label="News & Offers" />
                    <Typography variant={'heading2'} label="News & Offers" />
                    <Typography variant={'heading3'} label="News & Offers" />
                    <Typography variant={'heading4'} label="News & Offers" />
                    <Typography variant={'heading5'} label="News & Offers" />
                    <Typography variant={'heading6'} label="News & Offers" />
                    <Typography variant={'title'} label="Login" />
                    <div className='d-flex align-items-center flex-row flex-wrap text-copyright'>
                        <Typography variant={'copyright'} label="By continuing, you agree to the" color="color-gray02" />
                        <Button label="Terms & Conditions" variant="link-info" />
                        <Typography variant={'copyright'} label="and" color="color-gray02" />
                        <Button label="Privacy Policy" variant="link-info" />
                        <Typography variant={'copyright'} label="of TAB INDIA." color="color-gray02" />
                    </div>
                    <Typography variant={'subtitle'} margin="mb-1" label="Welcome " sublabel="Carl Chalou" />
                    <Typography variant={'subtitle-v1'} margin="mb-3" label="We are happy to welcome you again " sublabel="Carl Chalou" />
                    <Typography variant={'subtitle1'} color="color-info" label="Unfortunately, we are facing some issues with your account." />
                    <Typography variant={'welcomeTitle'} label="Welcome Back," sublabel="Carl Chalou" />
                    <Typography variant={'subtitle1'} label="Absolute Black-bb" />
                    <Typography variant={'subtitle2'} label="NATURAL STONE" />
                    <Typography variant={'body1'} color="color-gray03" label="TAB does not try to be just another natural stone factory. We live each production process with an indescribable passion. It has dawned upon us that we are not just natural stone suppliers – We are natural stone “dreamers”. That’s how TAB describes itself.  Now, we can say the same for Engineered Quartz Surfaces as well." />
                    <Typography variant={'body2'} label="Please feel free to connect with our support executive to rectify the issue." />
                    <Typography variant={'body3'} label="2cm - 5" infoSymbol="|" sublabel="3cm - 38" />
                    <Typography variant={'body4'} label="$5,248,100" />
                    <Typography variant={'price01'} label="$ 3,456.10" />
                </div>
            </div>

            {/* Tabs */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Tabs</h3>
                </div>
                <div className='card-body flex-column bg-secondary'>
                    <div>
                        <Tabs data={[
                            { title: 'Products', children: "Products Tab selected", disabled: false },
                            { title: 'Shipping Information', children: "Shipping Information Tab selected", disabled: false },
                            { title: 'Complaints', children: "Complaints Tab selected", disabled: false },
                            { title: 'Comments & Feedbacks', children: "Comments & Feedbacks Tab selected", disabled: false },
                            { title: 'Chats', children: "Chats Tab selected", disabled: false }
                        ]}
                            variant="tab-with-border"
                            selected={'Products'}
                        />
                    </div>
                    <div>
                        <Tabs data={[
                            { title: 'Orders', children: "Orders Tab selected", disabled: false },
                            { title: 'Order Ratings & Feedbacks', children: "Order Ratings & FeedbacksTab selected", disabled: false },
                            { title: 'Wishlists', children: "Wishlists Tab selected", disabled: false },
                            { title: 'Cart Products', children: "Cart Products Tab selected", disabled: false },
                            { title: 'Sub Users', children: "Sub Users selected", disabled: false },
                            { title: 'Offers', children: "Offers Tab selected", disabled: false }
                        ]}
                            variant="tab-with-border"
                            selected={'Products'}
                        />
                    </div>
                    <div>
                        <Tabs data={[
                            { title: 'Slabs', children: "Tab 1 selected", disabled: false },
                            { title: 'Tiles', children: "Tab 2 selected", disabled: false },
                            { title: 'Mosaics', children: "Tab 3 selected", disabled: false },
                            { title: 'Blocks', children: "Tab 4 selected", disabled: false },
                        ]}
                            variant="tab-with-button"
                            selected={'Slabs'}
                        />
                    </div>
                    <div>
                        <Tabs data={[
                            { title: 'Request', children: "Tab 1 selected", disabled: false },
                            { title: 'Open', children: "Tab 2 selected", disabled: false },
                            { title: 'History', children: "Tab 3 selected", disabled: false },
                        ]}
                            variant="tab-with-button"
                            selected={'Open'}
                        />
                    </div>
                </div>
            </div>


            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Dialogue</h3>
                </div>
                <div className='card-body'>
                    <Button label="Open Dialogue" variant="link-info" onClick={()=>setOpenModal(true)} />
                    <Dialogue isOpen={isOpenModal} handleClose={()=>setOpenModal(false)} label="Remove From Wish List"
                        children={<DialogueChildren />}
                    />


                </div>
            </div>

            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Loader</h3>
                </div>
                <div className='card-body'>
                    <GlobalSpinner />

                </div>
            </div>

            {/* Search */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Search</h3>
                </div>
                <div className='card-body d-flex gap-4 bg-secondary'>
                    <Search />
                </div>
            </div>

            {/* Checkbox */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Checkbox</h3>
                </div>
                <div className='card-body d-flex gap-4 bg-secondary'>
                    <CustomCheckbox id={'checkbox_1'} label="Checkbox Label" name={'checkDefault'}></CustomCheckbox>
                    <CustomCheckbox variant={'labelled'} label="Checkbox Label" id={'checkbox_2'} name={'checkLabelled'}></CustomCheckbox>
                </div>
            </div>

            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Drawer</h3>
                </div>
                <div className='card-body d-flex gap-4 bg-secondary'>
                    <Drawer data={leftDrwawer} handleCloseDrawer={handleCloseDrawer} />
                    <Button label="Open left Drawer" onClick={() => setLeftDrawer({ ...leftDrwawer, isOpen: true })} />

                </div>
            </div>

            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Toast</h3>
                </div>
                <div className='card-body d-flex gap-4'>
                    <Button label="Normal" onClick={() => showToastMessage("This is a Info ")} variant="primary" />
                    <Button label="Success" onClick={() => showSuccessMessage("This is a Success Message")} variant="primary" />
                    <Button label="Warning" onClick={() => showWarnMessage("This is a  Warning")} variant="primary" />
                    <Button label="Error" onClick={() => showErrorMessage("This is a Error ")} variant="primary" />
                    <Button label="Info" onClick={() => showInfoMessage("This is a Info ")} variant="primary" />
                </div>
            </div>
            
            {/* Badges */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Badges</h3>
                </div>
                    <div className='card-body d-flex gap-4 bg-secondary'>
                    <Badge bg="primary">Primary</Badge>
                    <Badge bg="secondary" text="dark">Secondary</Badge>
                    <Badge bg="success">Success</Badge>
                    <Badge bg="danger">Danger</Badge>
                    <Badge bg="warning" text="dark">Warning</Badge>
                    <Badge bg="info">Info</Badge>
                    <Badge bg="light" text="dark">Light</Badge>
                    <Badge bg="dark">Dark</Badge>
                    <Badge bg="blue">Blue</Badge>
                    <Badge bg="purple">Purple</Badge>
                    <Badge bg="cyan">Cyan</Badge>
                </div>
            </div>

        </div>
    )
}
