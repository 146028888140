import { GET_STORY_LIST_BEGINS, GET_STORY_LIST_FAILURE, GET_STORY_LIST_SUCCESS } from "../constants";

const INITIAL_STATE = {
	isLoading: false,
	story: []
};

const storyReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case GET_STORY_LIST_BEGINS:
			return { ...state, isLoading: true }
		case GET_STORY_LIST_SUCCESS:
			return { ...state, isLoading: false, story: action.data }
		case GET_STORY_LIST_FAILURE:
			return { ...state, isLoading: false }


		default:
			return state;
	}
};

export default storyReducer;