export const LOGIN_BEGINS = 'LOGIN_BEGINS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_BEGINS = 'LOGOUT_BEGINS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SEND_FORGOT_PASSWORD_BEGINS = 'SEND_FORGOT_PASSWORD_BEGINS';
export const SEND_FORGOT_PASSWORD_SUCCESS = 'SEND_FORGOT_PASSWORD_SUCCESS';
export const SEND_FORGOT_PASSWORD_FAILURE = 'SEND_FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_BEGINS = 'RESET_PASSWORD_BEGINS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const GET_USER_DETAILS_BEGINS = 'GET_USER_DETAILS_BEGINS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';


export const UPDATE_APP_BAR_TITLE_BEGINS = 'UPDATE_APP_BAR_TITLE_BEGINS';
export const UPDATE_APP_BAR_TITLE = 'UPDATE_APP_BAR_TITLE';

export const GET_CUSTOMER_LIST_BEGINS = 'GET_CUSTOMER_LIST_BEGINS';
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE';

export const SEND_OTP_BEGINS = 'SEND_OTP_BEGINS';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE';

export const INVITE_CUSTOMERS_BEGINS = 'INVITE_CUSTOMERS_BEGINS';
export const INVITE_CUSTOMERS_SUCCESS = 'INVITE_CUSTOMERS_SUCCESS';
export const INVITE_CUSTOMERS_FAILURE = 'INVITE_CUSTOMERS_FAILURE';

export const INVITE_INDIVIDUAL_CUSTOMERS_BEGINS = 'INVITE_INDIVIDUAL_CUSTOMERS_BEGINS';
export const INVITE_INDIVIDUAL_CUSTOMERS_SUCCESS = 'INVITE_INDIVIDUAL_CUSTOMERS_SUCCESS';
export const INVITE_INDIVIDUAL_CUSTOMERS_FAILURE = 'INVITE_INDIVIDUAL_CUSTOMERS_FAILURE';

export const UPDATE_CUSTOMERS_BEGINS = 'UPDATE_CUSTOMERS_BEGINS';
export const UPDATE_CUSTOMERS_SUCCESS = 'UPDATE_CUSTOMERS_SUCCESS';
export const UPDATE_CUSTOMERS_FAILURE = 'UPDATE_CUSTOMERS_FAILURE';

export const GET_SUBADMINS_BEGINS = 'GET_SUBADMINS_BEGINS';
export const GET_SUBADMINS_SUCCESS = 'GET_SUBADMINS_SUCCESS';
export const GET_SUBADMINS_FAILURE = 'GET_SUBADMINS_FAILURE';

export const CREATE_SUBADMIN_BEGINS = 'CREATE_SUBADMIN_BEGINS';
export const CREATE_SUBADMIN_SUCCESS = 'CREATE_SUBADMIN_SUCCESS';
export const CREATE_SUBADMIN_FAILURE = 'CREATE_SUBADMIN_FAILURE';

export const UPDATE_SUBADMIN_BEGINS = 'UPDATE_SUBADMIN_BEGINS';
export const UPDATE_SUBADMIN_SUCCESS = 'UPDATE_SUBADMIN_SUCCESS';
export const UPDATE_SUBADMIN_FAILURE = 'UPDATE_SUBADMIN_FAILURE';

export const GET_SALES_USERS_BEGINS = 'GET_SALES_USERS_BEGINS';
export const GET_SALES_USERS_SUCCESS = 'GET_SALES_USERS_SUCCESS';
export const GET_SALES_USERS_FAILURE = 'GET_SALES_USERS_FAILURE';

export const CREATE_SALES_USER_BEGINS = 'CREATE_SALES_USER_BEGINS';
export const CREATE_SALES_USER_SUCCESS = 'CREATE_SALES_USER_SUCCESS';
export const CREATE_SALES_USER_FAILURE = 'CREATE_SALES_USER_FAILURE';

export const UPDATE_SALES_USER_BEGINS = 'UPDATE_SALES_USER_BEGINS';
export const UPDATE_SALES_USER_SUCCESS = 'UPDATE_SALES_USER_SUCCESS';
export const UPDATE_SALES_USER_FAILURE = 'UPDATE_SALES_USER_FAILURE';

export const SEARCH_USER_BY_NAME_BEGINS = 'SEARCH_USER_BY_NAME_BEGINS';
export const SEARCH_USER_BY_NAME_SUCCESS = 'SEARCH_USER_BY_NAME_SUCCESS';
export const SEARCH_USER_BY_NAME_FAILURE = 'SEARCH_USER_BY_NAME_FAILURE';

export const SEARCH_CUSTOMER_EMAIL_BY_NAME_BEGINS = 'SEARCH_CUSTOMER_EMAIL_BY_NAME_BEGINS';
export const SEARCH_CUSTOMER_EMAIL_BY_NAME_SUCCESS = 'SEARCH_CUSTOMER_EMAIL_BY_NAME_SUCCESS';
export const SEARCH_CUSTOMER_EMAIL_BY_NAME_FAILURE = 'SEARCH_CUSTOMER_EMAIL_BY_NAME_FAILURE';

export const GET_SUBADMIN_PERMISSIONS_BEGINS = 'GET_SUBADMIN_PERMISSIONS_BEGINS';
export const GET_SUBADMIN_PERMISSIONS_SUCCESS = 'GET_SUBADMIN_PERMISSIONS_SUCCESS';
export const GET_SUBADMIN_PERMISSIONS_FAILURE = 'GET_SUBADMIN_PERMISSIONS_FAILURE';

export const GET_USER_DATA_WITH_AUTH_BEGINS = 'GET_USER_DATA_WITH_AUTH_BEGINS';
export const GET_USER_DATA_WITH_AUTH_SUCCESS = 'GET_USER_DATA_WITH_AUTH_SUCCESS';
export const GET_USER_DATA_WITH_AUTH_FAILURE = 'GET_USER_DATA_WITH_AUTH_FAILURE';

export const UPDATE_USER_STATUS_BEGINS = 'UPDATE_USER_STATUS_BEGINS';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_FAILURE = 'UPDATE_USER_STATUS_FAILURE';

export const GET_PRODUCT_LIST_BEGINS = 'GET_PRODUCT_LIST_BEGINS';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAILURE = 'GET_PRODUCT_LIST_FAILURE';

export const GET_PRODUCT_DETAILS_BEGINS = 'GET_PRODUCT_DETAILS_BEGINS'
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS'
export const GET_PRODUCT_DETAILS_FAILURE = 'GET_PRODUCT_DETAILS_FAILURE'

export const PRODUCT_FILTER_BEGINS = 'PRODUCT_FILTER_BEGINS'
export const PRODUCT_FILTER_SUCCESS = 'PRODUCT_FILTER_SUCCESS'
export const PRODUCT_FILTER_FAILURE = 'PRODUCT_FILTER_FAILURE'

export const UPDATE_PRODUCT_SORT_SUCCESS = 'UPDATE_PRODUCT_SORT_SUCCESS';

export const CREATE_PRODUCT_PERMISSIONS_BEGINS = 'CREATE_PRODUCT_PERMISSIONS_BEGINS';
export const CREATE_PRODUCT_PERMISSIONS_SUCCESS = 'CREATE_PRODUCT_PERMISSIONS_SUCCESS';
export const CREATE_PRODUCT_PERMISSIONS_FAILURE = 'CREATE_PRODUCT_PERMISSIONS_FAILURE';

export const GET_WISHLISTS_BEGINS = 'GET_WISHLISTS_BEGINS';
export const GET_WISHLISTS_SUCCESS = 'GET_WISHLISTS_SUCCESS';
export const GET_WISHLISTS_FAILURE = 'GET_WISHLISTS_FAILURE';

export const ADD_TO_WISHLIST_BEGINS = 'ADD_TO_WISHLIST_BEGINS';
export const ADD_TO_WISHLIST_SUCCESS = 'ADD_TO_WISHLIST_SUCCESS';
export const ADD_TO_WISHLIST_FAILURE = 'ADD_TO_WISHLIST_FAILURE';

export const SHOW_INTERESTED_PRODUCTS_BEGINS = 'SHOW_INTERESTED_PRODUCTS_BEGINS';
export const SHOW_INTERESTED_PRODUCTS_SUCCESS = 'SHOW_INTERESTED_PRODUCTS_SUCCESS';
export const SHOW_INTERESTED_PRODUCTS_FAILURE = 'SHOW_INTERESTED_PRODUCTS_FAILURE';

export const ADD_TOCART_BEGINS = 'ADD_TOCART_BEGINS';
export const ADD_TOCART_SUCCESS = 'ADD_TOCART_SUCCESS';
export const ADD_TOCART_FAILURE = 'ADD_TOCART_FAILURE';

export const GET_WISHLIST_ADDED_USERS_BEGINS = 'GET_WISHLIST_ADDED_USERS_BEGINS';
export const GET_WISHLIST_ADDED_USERS_SUCCESS = 'GET_WISHLIST_ADDED_USERS_SUCCESS';
export const GET_WISHLIST_ADDED_USERS_FAILURE = 'GET_WISHLIST_ADDED_USERS_FAILURE';

export const GET_CUSTOMER_DETAILS_BY_ID_BEGINS = 'GET_CUSTOMER_DETAILS_BY_ID_BEGINS';
export const GET_CUSTOMER_DETAILS_BY_ID_SUCCESS = 'GET_CUSTOMER_DETAILS_BY_ID_SUCCESS';
export const GET_CUSTOMER_DETAILS_BY_ID_FAILURE = 'GET_CUSTOMER_DETAILS_BY_ID_FAILURE';

export const GET_ORDERS_BEGINS = 'GET_ORDERS_BEGINS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

export const GET_ORDER_DETAILS_BY_ID_BEGINS = 'GET_ORDER_DETAILS_BY_ID_BEGINS';
export const GET_ORDER_DETAILS_BY_ID_SUCCESS = 'GET_ORDER_DETAILS_BY_ID_SUCCESS';
export const GET_ORDER_DETAILS_BY_ID_FAILURE = 'GET_ORDER_DETAILS_BY_ID_FAILURE';

export const GET_OPEN_ORDER_DETAILS_BY_ID_BEGINS = 'GET_OPEN_ORDER_DETAILS_BY_ID_BEGINS';
export const GET_OPEN_ORDER_DETAILS_BY_ID_SUCCESS = 'GET_OPEN_ORDER_DETAILS_BY_ID_SUCCESS';
export const GET_OPEN_ORDER_DETAILS_BY_ID_FAILURE = 'GET_OPEN_ORDER_DETAILS_BY_ID_FAILURE';

export const GET_REQUEST_ORDER_DETAILS_BY_ID_BEGINS = 'GET_REQUEST_ORDER_DETAILS_BY_ID_BEGINS';
export const GET_REQUEST_ORDER_DETAILS_BY_ID_SUCCESS = 'GET_REQUEST_ORDER_DETAILS_BY_ID_SUCCESS';
export const GET_REQUEST_ORDER_DETAILS_BY_ID_FAILURE = 'GET_REQUEST_ORDER_DETAILS_BY_ID_FAILURE';

export const CHANGE_CUSTOMER_STATUS_BEGINS = 'CHANGE_CUSTOMER_STATUS_BEGINS';
export const CHANGE_CUSTOMER_STATUS_SUCCESS = 'CHANGE_CUSTOMER_STATUS_SUCCESS';
export const CHANGE_CUSTOMER_STATUS_FAILURE = 'CHANGE_CUSTOMER_STATUS_FAILURE';

export const UPDATE_ORDER_DESCRIPTION_BEGINS = 'UPDATE_ORDER_DESCRIPTION_BEGINS';
export const UPDATE_ORDER_DESCRIPTION_SUCCESS = 'UPDATE_ORDER_DESCRIPTION_SUCCESS';
export const UPDATE_ORDER_DESCRIPTION_FAILURE = 'UPDATE_ORDER_DESCRIPTION_FAILURE';

export const UPDATE_PRODUCT_DESCRIPTION_BEGINS = 'UPDATE_PRODUCT_DESCRIPTION_BEGINS';
export const UPDATE_PRODUCT_DESCRIPTION_SUCCESS = 'UPDATE_PRODUCT_DESCRIPTION_SUCCESS';
export const UPDATE_PRODUCT_DESCRIPTION_FAILURE = 'UPDATE_PRODUCT_DESCRIPTION_FAILURE';

export const APPROVE_ORDER_REQUEST_BEGINS = 'APPROVE_ORDER_REQUEST_BEGINS';
export const APPROVE_ORDER_REQUEST_SUCCESS = 'APPROVE_ORDER_REQUEST_SUCCESS';
export const APPROVE_ORDER_REQUEST_FAILURE = 'APPROVE_ORDER_REQUEST_FAILURE';

export const UPDATE_SHIPPING_ADDRESS_BEGINS = 'UPDATE_SHIPPING_ADDRESS_BEGINS';
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = 'UPDATE_SHIPPING_ADDRESS_SUCCESS';
export const UPDATE_SHIPPING_ADDRESS_FAILURE = 'UPDATE_SHIPPING_ADDRESS_FAILURE';

export const GET_ADDRESS_LIST_BEGINS = 'GET_ADDRESS_LIST_BEGINS';
export const GET_ADDRESS_LIST_SUCCESS = 'GET_ADDRESS_LIST_SUCCESS';
export const GET_ADDRESS_LIST_FAILURE = 'GET_ADDRESS_LIST_FAILURE';

export const DELETE_ORDER_SUMMARY_BEGINS = 'DELETE_ORDER_SUMMARY_BEGINS';
export const DELETE_ORDER_SUMMARY_SUCCESS = 'DELETE_ORDER_SUMMARY_SUCCESS';
export const DELETE_ORDER_SUMMARY_FAILURE = 'DELETE_ORDER_SUMMARY_FAILURE';

export const UPDATE_ALL_ORDER_BEGINS = 'UPDATE_ALL_ORDER_BEGINS';
export const UPDATE_ALL_ORDER_SUCCESS = 'UPDATE_ALL_ORDER_SUCCESS';
export const UPDATE_ALL_ORDER_FAILURE = 'UPDATE_ALL_ORDER_FAILURE';

export const UPDATE_CHOOSED_ADDRESSES_BEGINS = 'UPDATE_CHOOSED_ADDRESSES_BEGINS';
export const UPDATE_CHOOSED_ADDRESSES_SUCCESS = 'UPDATE_CHOOSED_ADDRESSES_SUCCESS';
export const UPDATE_CHOOSED_ADDRESSES_FAILURE = 'UPDATE_CHOOSED_ADDRESSES_FAILURE';

export const UPDATE_ORDER_PRICE_BEGINS = 'UPDATE_ORDER_PRICE_BEGINS';
export const UPDATE_ORDER_PRICE_SUCCESS = 'UPDATE_ORDER_PRICE_SUCCESS';
export const UPDATE_ORDER_PRICE_FAILURE = 'UPDATE_ORDER_PRICE_FAILURE';

export const ADD_NEW_UNIT_BEGINS = "ADD_NEW_UNIT_BEGINS"
export const ADD_NEW_UNIT_SUCCESS = "ADD_NEW_UNIT_SUCCESS"
export const ADD_NEW_UNIT_FAILURE = "ADD_NEW_UNIT_FAILURE"

export const GET_UNIT_LIST_BEGINS = "GET_UNIT_LIST_BEGINS"
export const GET_UNIT_LIST_SUCCESS = "GET_UNIT_LIST_SUCCESS"
export const GET_UNIT_LIST_FAILURE = "GET_UNIT_LIST_FAILURE"

export const ASSIGN_SALES_USER_TO_CUSTOMERS_BEGINS = "ASSIGN_SALES_USER_TO_CUSTOMERS_BEGINS"
export const ASSIGN_SALES_USER_TO_CUSTOMERS_SUCCESS = "ASSIGN_SALES_USER_TO_CUSTOMERS_SUCCESS"
export const ASSIGN_SALES_USER_TO_CUSTOMERS_FAILURE = "ASSIGN_SALES_USER_TO_CUSTOMERS_FAILURE"

export const GET_PROMOTIONS_LIST_BEGINS = "GET_PROMOTIONS_LIST_BEGINS"
export const GET_PROMOTIONS_LIST_SUCCESS = "GET_PROMOTIONS_LIST_SUCCESS"
export const GET_PROMOTIONS_LIST_FAILURE = "GET_PROMOTIONS_LIST_FAILURE"

export const GET_PROMOTIONS_DETAILS_BEGINS = "GET_PROMOTIONS_DETAILS_BEGINS"
export const GET_PROMOTIONS_DETAILS_SUCCESS = "GET_PROMOTIONS_DETAILS_SUCCESS"
export const GET_PROMOTIONS_DETAILS_FAILURE = "GET_PROMOTIONS_DETAILS_FAILURE"

export const GET_OFFERS_LIST_BEGINS = "GET_OFFERS_LIST_BEGINS"
export const GET_OFFERS_LIST_SUCCESS = "GET_OFFERS_LIST_SUCCESS"
export const GET_OFFERS_LIST_FAILURE = "GET_OFFERS_LIST_FAILURE"

export const GET_OFFERS_DETAILS_BEGINS = "GET_OFFERS_DETAILS_BEGINS"
export const GET_OFFERS_DETAILS_SUCCESS = "GET_OFFERS_DETAILS_SUCCESS"
export const GET_OFFERS_DETAILS_FAILURE = "GET_OFFERS_DETAILS_FAILURE"

export const GET_FILTER_LIST_BEGINS = "GET_FILTER_LIST_BEGINS"
export const GET_FILTER_LIST_SUCCESS = "GET_FILTER_LIST_SUCCESS"
export const GET_FILTER_LIST_FAILURE = "GET_FILTER_LIST_FAILURE"

export const CREATE_PROMOTION_BEGINS = "CREATE_PROMOTION_BEGINS"
export const CREATE_PROMOTION_SUCCESS = "CREATE_PROMOTION_SUCCESS"
export const CREATE_PROMOTION_FAILURE = "CREATE_PROMOTION_FAILURE"

export const UPDATE_PROMOTION_BEGINS = "UPDATE_PROMOTION_BEGINS"
export const UPDATE_PROMOTION_SUCCESS = "UPDATE_PROMOTION_SUCCESS"
export const UPDATE_PROMOTION_FAILURE = "UPDATE_PROMOTION_FAILURE"


export const CREATE_OFFER_BEGINS = "CREATE_OFFER_BEGINS"
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS"
export const CREATE_OFFER_FAILURE = "CREATE_OFFER_FAILURE"

export const UPDATE_OFFER_BEGINS = "UPDATE_OFFER_BEGINS"
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS"
export const UPDATE_OFFER_FAILURE = "UPDATE_OFFER_FAILURE"

export const UPDATE_PROMOTION_STATUS_BEGINS = "UPDATE_PROMOTION_STATUS_BEGINS"
export const UPDATE_PROMOTION_STATUS_SUCCESS = "UPDATE_PROMOTION_STATUS_SUCCESS"
export const UPDATE_PROMOTION_STATUS_FAILURE = "UPDATE_PROMOTION_STATUS_FAILURE"

export const UPDATE_OFFER_STATUS_BEGINS = "UPDATE_OFFER_STATUS_BEGINS"
export const UPDATE_OFFER_STATUS_SUCCESS = "UPDATE_OFFER_STATUS_SUCCESS"
export const UPDATE_OFFER_STATUS_FAILURE = "UPDATE_OFFER_STATUS_FAILURE"

export const DELETE_QUICK_PROMOTION_BEGINS = "DELETE_QUICK_PROMOTION_BEGINS"
export const DELETE_QUICK_PROMOTION_SUCCESS = "DELETE_QUICK_PROMOTION_SUCCESS"
export const DELETE_QUICK_PROMOTION_FAILURE = "DELETE_QUICK_PROMOTION_FAILURE"

export const MANAGE_QUICK_PROMOTIONS_LIST_BEGINS = "MANAGE_QUICK_PROMOTIONS_LIST_BEGINS"
export const MANAGE_QUICK_PROMOTIONS_LIST_SUCCESS = "MANAGE_QUICK_PROMOTIONS_LIST_SUCCESS"
export const MANAGE_QUICK_PROMOTIONS_LIST_FAILURE = "MANAGE_QUICK_PROMOTIONS_LIST_FAILURE"

export const CREATE_QUICK_PROMOTION_BEGINS = "CREATE_QUICK_PROMOTION_BEGINS"
export const CREATE_QUICK_PROMOTION_SUCCESS = "CREATE_QUICK_PROMOTION_SUCCESS"
export const CREATE_QUICK_PROMOTION_FAILURE = "CREATE_QUICK_PROMOTION_FAILURE"

export const DELETE_IMAGE_BEGINS = "DELETE_IMAGE_BEGINS"
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS"
export const DELETE_IMAGE_FAILURE = "DELETE_IMAGE_FAILURE"

export const DELETE_PROMOTION_BEGINS = "DELETE_PROMOTION_BEGINS"
export const DELETE_PROMOTION_SUCCESS = "DELETE_PROMOTION_SUCCESS"
export const DELETE_PROMOTION_FAILURE = "DELETE_PROMOTION_FAILURE"

export const DELETE_OFFER_BEGINS = "DELETE_OFFER_BEGINS"
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS"
export const DELETE_OFFER_FAILURE = "DELETE_OFFER_FAILURE"

export const GET_SUB_USERS_LIST_BEGINS = "GET_SUB_USERS_LIST_BEGINS"
export const GET_SUB_USERS_LIST_SUCCESS = "GET_SUB_USERS_LIST_SUCCESS"
export const GET_SUB_USERS_LIST_FAILURE = "GET_SUB_USERS_LIST_FAILURE"

export const GET_CUSTOMER_OFFERS_LIST_BEGINS = "GET_CUSTOMER_OFFERS_LIST_BEGINS"
export const GET_CUSTOMER_OFFERS_LIST_SUCCESS = "GET_CUSTOMER_OFFERS_LIST_SUCCESS"
export const GET_CUSTOMER_OFFERS_LIST_FAILURE = "GET_CUSTOMER_OFFERS_LIST_FAILURE"

export const GET_CUSTOMER_CART_LIST_BEGINS = "GET_CUSTOMER_CART_LIST_BEGINS"
export const GET_CUSTOMER_CART_LIST_SUCCESS = "GET_CUSTOMER_CART_LIST_SUCCESS"
export const GET_CUSTOMER_CART_LIST_FAILURE = "GET_CUSTOMER_CART_LIST_FAILURE"

export const GET_ORDER_RATINGS_AND_FEEDBACK_BEGINS = "GET_ORDER_RATINGS_AND_FEEDBACK_BEGINS"
export const GET_ORDER_RATINGS_AND_FEEDBACK_SUCCESS = "GET_ORDER_RATINGS_AND_FEEDBACK_SUCCESS"
export const GET_ORDER_RATINGS_AND_FEEDBACK_FAILURE = "GET_ORDER_RATINGS_AND_FEEDBACK_FAILURE"

export const GET_SUB_ADMIN_DETAILS_BEGINS = "GET_SUB_ADMIN_DETAILS_BEGINS"
export const GET_SUB_ADMIN_DETAILS_SUCCESS = "GET_SUB_ADMIN_DETAILS_SUCCESS"
export const GET_SUB_ADMIN_DETAILS_FAILURE = "GET_SUB_ADMIN_DETAILS_FAILURE"

export const GET_SALES_USER_DETAILS_BY_ID_BEGINS = "GET_SALES_USER_DETAILS_BY_ID_BEGINS"
export const GET_SALES_USER_DETAILS_BY_ID_SUCCESS = "GET_SALES_USER_DETAILS_BY_ID_SUCCESS"
export const GET_SALES_USER_DETAILS_BY_ID_FAILURE = "GET_SALES_USER_DETAILS_BY_ID_FAILURE"

export const GET_CUSTOMER_WISHLIST_BEGINS = "GET_CUSTOMER_WISHLIST_BEGINS"
export const GET_CUSTOMER_WISHLIST_SUCCESS = "GET_CUSTOMER_WISHLIST_SUCCESS"
export const GET_CUSTOMER_WISHLIST_FAILURE = "GET_CUSTOMER_WISHLIST_FAILURE"

export const GET_CUSTOMER_ORDER_LIST_BEGINS = "GET_CUSTOMER_ORDER_LIST_BEGINS"
export const GET_CUSTOMER_ORDER_LIST_SUCCESS = "GET_CUSTOMER_ORDER_LIST_SUCCESS"
export const GET_CUSTOMER_ORDER_LIST_FAILURE = "GET_CUSTOMER_ORDER_LIST_FAILURE"

export const GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_BEGINS = "GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_BEGINS"
export const GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_SUCCESS = "GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_SUCCESS"
export const GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_FAILURE = "GET_CUSTOMER_INTERESTED_PRODUCTS_LIST_FAILURE"

export const UPLOAD_PROFILE_IMAGE_BEGINS = "UPLOAD_PROFILE_IMAGE_BEGINS"
export const UPLOAD_PROFILE_IMAGE_SUCCESS = "UPLOAD_PROFILE_IMAGE_SUCCESS"
export const UPLOAD_PROFILE_IMAGE_FAILURE = "UPLOAD_PROFILE_IMAGE_FAILURE"

export const UPDATE_MEMBERSHIP_BEGINS = "UPDATE_MEMBERSHIP_BEGINS"
export const UPDATE_MEMBERSHIP_SUCCESS = "UPDATE_MEMBERSHIP_SUCCESS"
export const UPDATE_MEMBERSHIP_FAILURE = "UPDATE_MEMBERSHIP_FAILURE"

export const GET_DELIVERED_ORDER_DETAILS_BY_ID_BEGINS = 'GET_DELIVERED_ORDER_DETAILS_BY_ID_BEGINS';
export const GET_DELIVERED_ORDER_DETAILS_BY_ID_SUCCESS = 'GET_DELIVERED_ORDER_DETAILS_BY_ID_SUCCESS';
export const GET_DELIVERED_ORDER_DETAILS_BY_ID_FAILURE = 'GET_DELIVERED_ORDER_DETAILS_BY_ID_FAILURE';

export const GET_SEARATES_DETAILS_BEGINS = "GET_SEARATES_DETAILS_BEGINS"
export const GET_SEARATES_DETAILS_FAILURE = "GET_SEARATES_DETAILS_FAILURE"
export const GET_SEARATES_DETAILS_SUCCESS = "GET_SEARATES_DETAILS_SUCCESS"

export const UPDATE_EMAIL_AND_INVITE_CUSTOMER_BEGINS = "UPDATE_EMAIL_AND_INVITE_CUSTOMER_BEGINS"
export const UPDATE_EMAIL_AND_INVITE_CUSTOMER_SUCCESS = "UPDATE_EMAIL_AND_INVITE_CUSTOMER_SUCCESS"
export const UPDATE_EMAIL_AND_INVITE_CUSTOMER_FAILURE = "UPDATE_EMAIL_AND_INVITE_CUSTOMER_FAILURE"

export const CREATE_ORDER_BEGINS = "CREATE_ORDER_BEGINS"
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE"

export const GET_ASSIGN_PRODUCTS_LIST_BEGINS = "GET_ASSIGN_PRODUCTS_LIST_BEGINS"
export const GET_ASSIGN_PRODUCTS_LIST_SUCCESS = "GET_ASSIGN_PRODUCTS_LIST_SUCCESS"
export const GET_ASSIGN_PRODUCTS_LIST_FAILURE = "GET_ASSIGN_PRODUCTS_LIST_FAILURE"

export const CHANGE_CUSTOMER_PRODUCT_NAME_BEGINS = "CHANGE_CUSTOMER_PRODUCT_NAME_BEGINS"
export const CHANGE_CUSTOMER_PRODUCT_NAME_SUCCESS = "CHANGE_CUSTOMER_PRODUCT_NAME_SUCCESS"
export const CHANGE_CUSTOMER_PRODUCT_NAME_FAILURE = "CHANGE_CUSTOMER_PRODUCT_NAME_FAILURE"

export const UPDATE_ORDER_CREDIT_HOLD_BEGINS = "UPDATE_ORDER_CREDIT_HOLD_BEGINS"
export const UPDATE_ORDER_CREDIT_HOLD_SUCCESS = "UPDATE_ORDER_CREDIT_HOLD_SUCCESS"
export const UPDATE_ORDER_CREDIT_HOLD_FAILURE = "UPDATE_ORDER_CREDIT_HOLD_FAILURE"

export const UPDATE_PRODUCT_VISIBILITY_STATUS_BEGINS = "UPDATE_PRODUCT_VISIBILITY_STATUS_BEGINS"
export const UPDATE_PRODUCT_VISIBILITY_STATUS_SUCCESS = "UPDATE_PRODUCT_VISIBILITY_STATUS_SUCCESS"
export const UPDATE_PRODUCT_VISIBILITY_STATUS_FAILURE = "UPDATE_PRODUCT_VISIBILITY_STATUS_FAILURE"

export const CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_BEGINS = "CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_BEGINS"
export const CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_SUCCESS = "CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_SUCCESS"
export const CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_FAILURE = "CREATE_NEW_PRODUCT_NOTIFICATION_SETTINGS_FAILURE"

export const CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_BEGINS = "CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_BEGINS"
export const CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_SUCCESS = "CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_SUCCESS"
export const CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_FAILURE = "CREATE_ADDITIONAL_NOTIFICATION_SETTINGS_FAILURE"

export const GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_BEGINS = "GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_BEGINS"
export const GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_SUCCESS = "GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_SUCCESS"
export const GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_FAILURE = "GET_CUSTOMERS_ADDED_PRODUCT_ON_CART_FAILURE"

export const GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_BEGINS = "GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_BEGINS"
export const GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_SUCCESS = "GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_SUCCESS"
export const GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_FAILURE = "GET_CUSTOMERS_ADDED_PRODUCT_IN_INTERESTED_FAILURE"

export const GET_PRODUCT_ORDERS_LIST_BEGINS = "GET_PRODUCT_ORDERS_LIST_BEGINS"
export const GET_PRODUCT_ORDERS_LIST_SUCCESS = "GET_PRODUCT_ORDERS_LIST_SUCCESS"
export const GET_PRODUCT_ORDERS_LIST_FAILURE = "GET_PRODUCT_ORDERS_LIST_FAILURE"

export const GET_RATINGS_AND_FEEDBACKS_LIST_BEGINS = "GET_RATINGS_AND_FEEDBACKS_LIST_BEGINS"
export const GET_RATINGS_AND_FEEDBACKS_LIST_SUCCESS = "GET_RATINGS_AND_FEEDBACKS_LIST_SUCCESS"
export const GET_RATINGS_AND_FEEDBACKS_LIST_FAILURE = "GET_RATINGS_AND_FEEDBACKS_LIST_FAILURE"

export const DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_BEGINS = "DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_BEGINS"
export const DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_SUCCESS = "DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_SUCCESS"
export const DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_FAILURE = "DELETE_ADDITIONAL_NOTIFICATION_SETTINGS_FAILURE"

export const UPLOAD_PRODUCT_IMAGE_BEGINS = "UPLOAD_PRODUCT_IMAGE_BEGINS"
export const UPLOAD_PRODUCT_IMAGE_SUCCESS = "UPLOAD_PRODUCT_IMAGE_SUCCESS"
export const UPLOAD_PRODUCT_IMAGE_FAILURE = "UPLOAD_PRODUCT_IMAGE_FAILURE"

export const DELETE_PRODUCT_IMAGE_BEGINS = "DELETE_PRODUCT_IMAGE_BEGINS"
export const DELETE_PRODUCT_IMAGE_SUCCESS = "DELETE_PRODUCT_IMAGE_SUCCESS"
export const DELETE_PRODUCT_IMAGE_FAILURE = "DELETE_PRODUCT_IMAGE_FAILURE"

export const GET_DASHBOARD_BEGINS = "GET_DASHBOARD_BEGINS"
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS"
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE"

export const GET_CUSTOMER_SALES_USERS_BEGINS = "GET_CUSTOMER_SALES_USERS_BEGINS"
export const GET_CUSTOMER_SALES_USERS_SUCCESS = "GET_CUSTOMER_SALES_USERS_SUCCESS"
export const GET_CUSTOMER_SALES_USERS_FAILURE = "GET_CUSTOMER_SALES_USERS_FAILURE"

export const DELETE_PROFILE_IMAGE_BEGINS = "DELETE_PROFILE_IMAGE_BEGINS"
export const DELETE_PROFILE_IMAGE_SUCCESS = "DELETE_PROFILE_IMAGE_SUCCESS"
export const DELETE_PROFILE_IMAGE_FAILURE = "DELETE_PROFILE_IMAGE_FAILURE"

export const GET_MY_PROFILE_BEGINS = "GET_MY_PROFILE_BEGINS"
export const GET_MY_PROFILE_SUCCESS = "GET_MY_PROFILE_SUCCESS"
export const GET_MY_PROFILE_FAILURE = "GET_MY_PROFILE_FAILURE"

export const DELETE_SALES_USER_CUSTOMER_BEGINS = "DELETE_SALES_USER_CUSTOMER_BEGINS"
export const DELETE_SALES_USER_CUSTOMER_SUCCESS = "DELETE_SALES_USER_CUSTOMER_SUCCESS"
export const DELETE_SALES_USER_CUSTOMER_FAILURE = "DELETE_SALES_USER_CUSTOMER_FAILURE"

export const CREATE_BULK_ORDER_BEGINS = "CREATE_BULK_ORDER_BEGINS"
export const CREATE_BULK_ORDER_SUCCESS = "CREATE_BULK_ORDER_SUCCESS"
export const CREATE_BULK_ORDER_FAILURE = "CREATE_BULK_ORDER_FAILURE"

export const PROFILE_CHANGE_PASSWORD_BEGINS = "PROFILE_CHANGE_PASSWORD_BEGINS"
export const PROFILE_CHANGE_PASSWORD_SUCCESS = "PROFILE_CHANGE_PASSWORD_SUCCESS"
export const PROFILE_CHANGE_PASSWORD_FAILURE = "PROFILE_CHANGE_PASSWORD_FAILURE"

export const UPDATE_PROFILE_DETAILS_BEGINS = "UPDATE_PROFILE_DETAILS_BEGINS"
export const UPDATE_PROFILE_DETAILS_SUCCESS = "UPDATE_PROFILE_DETAILS_SUCCESS"
export const UPDATE_PROFILE_DETAILS_FAILURE = "UPDATE_PROFILE_DETAILS_FAILURE"

export const GET_SELECTED_PRODUCT_FILTERS_SUCCESS = "GET_SELECTED_PRODUCT_FILTERS_SUCCESS"

export const GET_PRODUCT_SEARCH_SUCCESS = "GET_PRODUCT_SEARCH_SUCCESS"

export const GET_PRODUCT_PAGINATION_SUCCESS = "GET_PRODUCT_PAGINATION_SUCCESS"

export const GET_STORY_LIST_BEGINS = "GET_STORY_LIST_BEGINS"
export const GET_STORY_LIST_SUCCESS = "GET_STORY_LIST_SUCCESS"
export const GET_STORY_LIST_FAILURE = "GET_STORY_LIST_FAILURE"

export const UPLOAD_STORY_IMAGE_BEGINS = "UPLOAD_STORY_IMAGE_BEGINS"
export const UPLOAD_STORY_IMAGE_SUCCESS = "UPLOAD_STORY_IMAGE_SUCCESS"
export const UPLOAD_STORY_IMAGE_FAILURE = "UPLOAD_STORY_IMAGE_FAILURE"

export const DELETE_STORY_IMAGE_BEGINS = "DELETE_STORY_IMAGE_BEGINS"
export const DELETE_STORY_IMAGE_SUCCESS = "DELETE_STORY_IMAGE_SUCCESS"
export const DELETE_STORY_IMAGE_FAILURE = "DELETE_STORY_IMAGE_FAILURE"