import { applyMiddleware,  createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import monitorReducersEnhancer from '../monitorEnhanceReducers'
// import loggerMiddleware from '../logger'
import appReducers from '../reducers'

/*
     configurable middleware written by shahnad
*/ 


export default function configureStore(preloadedState) {
  // const middlewares = [loggerMiddleware, thunkMiddleware]
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(appReducers, preloadedState, composedEnhancers)

  return store
}