import React from 'react';
import { Stepper } from 'react-form-stepper';
import "./index.scss"

export const CustomStepper = ({ steps, activeStep, hideConnectors, variant }) => {

    const renderStepper = () => {
        switch (variant) {
            case 'order-status':

                return (<Stepper
                    hideConnectors={hideConnectors}
                    styleConfig={{
                        activeBgColor: '#D7D7D7',
                        completedBgColor: '#5D61CD',
                    }}
                    connectorStyleConfig={{
                        size: 10,
                        style: 'solid',
                    }}
                    className="order-status"
                    stepClassName="order-status-step"
                    steps={steps}
                    activeStep={activeStep}
                />)
            case 'promotion-form':
                return (<Stepper
                    styleConfig={{
                        activeBgColor: '#F27174',
                        completedBgColor: '#EC2B2F',
                        size: '4rem',
                    }}
                    connectorStyleConfig={{
                        size: 4,
                        style: 'solid',
                        // activeBgColor: '#F27174',
                        // completedBgColor: '#EC2B2F',
                        // disabledColor: '#D7D7D7',
                    }}
                    className='promotion-form'
                    stepClassName="promotion-form-step"
                    hideConnectors={hideConnectors}
                    steps={steps}
                    activeStep={activeStep}
                />)

            default:
                return (<Stepper
                    hideConnectors={hideConnectors}
                    steps={steps}
                    activeStep={activeStep}
                />)
        }

    }


    return (
        <div className='stepper'>
            {renderStepper()}
        </div>
    )
}
