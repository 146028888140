import React, { useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import moment from 'moment';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';

import { Avatar, Button, Card, Typography } from '../../../../components'
import { CrossXIcon, PaperPlaneIcon, TagCloseIcon } from '../../../../components/SVGIcons';
import { doc, updateDoc, getDocs, collection, setDoc, query, where } from "@firebase/firestore";

import { db } from "../../../../firebase";

import "./index.scss"

const ChatBody = ({ chatHistory, userId, customer, getUserChat, fetchPost, handleChatBody }) => {

    const [message, setMessage] = useState("")
    const [showMessage, setShowMessage] = useState(false)
    const [isTag, setTag] = useState(true);
    const sender = JSON.parse(localStorage.getItem('userData'));
    const { customerSalesUsers, isTableLoading } = useSelector((state) => state?.userManagement)
    const noImage = 'https://api.tab.devateam.com/images/avatar.png';

    const handleOnChange = (e) => {
        setMessage(e.target.value)
    }

    const handleSubmit = async (e) => {
        const email = customer?.email;
        const orderId = chatHistory[chatHistory.length - 1]?.orderId;
        e.preventDefault()
        if (message.trim() === "") {
            // alert("Enter valid message");
            return;
        }
        const q = query(collection(db, 'users'), where('email', '==', email));
        const querySnapShot = await getDocs(q);
        const queryData = querySnapShot.docs.map((e) => ({
            id: e.id
        }));

        // const res = await doc(db, `users/${e.id}`).set({ capital: true })
        queryData.map(async (e) => {
            await updateDoc(doc(db, `users/${e.id}`), {
                lastMessage: message,
                lastMessageTime: new Date(),
                isSeen: true,

            });
            await setDoc(doc(db, `users/${e.id}/messages`, new Date().toISOString()), {
                message: message,
                orderId: (orderId && isTag) ? orderId?.toString() : "",
                createdOn: new Date(),
                senderId: sender?.email,
                senderName: sender?.username,
                profilePic: sender.profileImage,
            });
        })
        getUserChat(customer)
        fetchPost()
        setMessage("");
    }
    return (
        <>
            <div className='chat-body flex-fill-column'>
                <Card>
                    {customer ?
                        <>
                            <div className='d-flex justify-content-between px-3 py-2'>
                                <div className='d-flex align-items-center chat-header'>
                                    {/* const customerSalesUser = customerSalesUsers.find(person => person?.email === customer?.email?.toLowerCase()); */}
                                    <Avatar variant={'header-pro-thumb'} parentClass={'active'} bsPrefix src={customer?.image ?? noImage} />
                                    <div className='d-flex flex-column ps-2 header-pro-title'>
                                        <Typography variant={"dynamic-text"} fontSize={'text-14 text-truncate'} fontWeight={'font-semibold'} color={'color-dark01'} margin="mb-0" label={customer?.name} />
                                        <Typography variant={"dynamic-text"} fontSize={'text-11 lh-1 text-truncate'} fontWeight={'font-medium'} color={'color-primary'} margin="mb-0" label={customer?.email} />
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center ms-2 chat-dropdown">

                                    <Button onClick={handleChatBody} icon={<CrossXIcon />} variant="secondary-rounded" customClass={'bg-transparent d-inline-flex d-lg-none'} />
                                </div>
                            </div>
                            <hr />
                            {/* <div className='d-flex align-items-center justify-content-center'>
                                <Typography label={"Today"} variant={"dynamic-text"} fontSize={'text-12'} fontWeight={'font-medium'} color={'color-gray'} margin="mb-3" />
                            </div> */}
                            <div className='flex-fill-row py-3 px-3 px-xl-4 overflow-hidden overflow-y-auto flex-column-reverse'>

                                <div>
                                    {
                                        chatHistory.map((e, i) => {
                                            const customerSalesUser = customerSalesUsers.find(person => person.email === e.senderId?.toLowerCase());
                                            const today = moment();
                                            const yesterday = moment().subtract(1, 'day');
                                            const dateToCheck = moment(e?.createdOn?.toDate());
                                            const isYesterday = dateToCheck.isSame(yesterday, 'day');
                                            const previousDate = i > 0 ? moment(chatHistory[i - 1]?.createdOn?.toDate()) : null;
                                            const day = dateToCheck.isSame(today, 'day') ? 'Today' : isYesterday ? 'Yesterday' : moment(e?.createdOn?.toDate()).format("MMM Do YY");
                                            return (<><Typography variant={'dynamic-text'} fontSize={'text-12'} fontWeight={'font-medium'} color={'color-gray'} margin={'text-center my-2'} label={!dateToCheck.isSame(previousDate, 'day') && day} />
                                                {(e.senderId?.toLowerCase() == sender.email?.toLowerCase()) ?
                                                    <div className='d-flex justify-content-end ps-3 ps-lg-5 py-1'>
                                                        <div className='d-flex flex-row gap-2'>
                                                            <Card variant={"chat-card"} className="chat-box chat-me arrow-right">
                                                                <Typography variant={"dynamic-text"} fontSize={'text-15'} fontWeight={'font-medium'} color={'chat-message'} margin="mb-0" label={e.message} />
                                                                <div className='chat-time'>
                                                                    <Typography variant={"dynamic-text"} fontSize={'text-12'} fontWeight={'font-regular'} margin="mb-0" label={moment(e?.createdOn?.toDate()).format('LT')} />
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    </div> :
                                                    <div className='d-flex justify-content-start pe-3 pe-lg-5 py-1'>
                                                        <div className='d-flex flex-row gap-2'>
                                                            {/* <Avatar variant={'header-pro-thumb'} bsPrefix src={e.profilePic} /> */}
                                                            <Avatar variant={'header-pro-thumb'} bsPrefix src={customer?.image ?? noImage} />

                                                            <Card variant={"chat-card"} className="chat-box arrow-left">
                                                                <Typography variant={"dynamic-text"} fontSize={'text-14'} fontWeight={'font-semibold'} color={'chat-name'} margin="mb-0" label={customerSalesUser ? (customerSalesUser?.first_name ?? ' ') + ' ' + (customerSalesUser?.last_name ?? ' ') : 'No name'} />
                                                                <Typography variant={"dynamic-text"} fontSize={'text-15'} fontWeight={'font-medium'} color={'chat-message'} margin="mb-0" label={e.message} />
                                                                <div className='chat-time'>
                                                                    <Typography variant={"dynamic-text"} fontSize={'text-12'} fontWeight={'font-regular'} margin="mb-0" label={moment(e?.createdOn?.toDate()).format('LT')} />
                                                                </div>
                                                            </Card>
                                                        </div>


                                                    </div>}
                                            </>)
                                        }
                                        )}



                                    {showMessage ? <div className='d-flex justify-content-end ps-3 ps-lg-5 py-1'>
                                        <div className='d-flex flex-row gap-2'>
                                            <Card variant={"chat-card"} className="chat-box chat-me arrow-left">
                                                <Typography variant={"body3"} margin={"mb-0"} label={message} />
                                                <div className='chat-time'>
                                                    <Typography variant={"dynamic-text"} fontSize={'text-12'} fontWeight={'font-regular'} margin="mb-0" label={"1 sec ago"} />
                                                </div>
                                            </Card>
                                        </div>
                                    </div> : <></>}
                                </div>
                            </div>
                            <hr />
                            <div className='d-flex align-items-center pt-1 pb-2 chat-editor'>
                                <Col xs={'auto'} className={'px-2 px-lg-3'}>
                                    {/* <span className='d-flex justify-content-center'>
                                        <EmojiIcon />
                                    </span> */}
                                </Col>
                                <Col xs={'auto'} className={''}>
                                    {
                                        chatHistory[chatHistory.length - 1]?.orderId && isTag &&
                                        <div className='custom-tag gap-2 d-flex align-items-center'><span># {chatHistory[chatHistory.length - 1]?.orderId}</span ><span onClick={() => setTag(false)}><TagCloseIcon /></span></div>
                                    }
                                </Col>
                                <Col xs={9} className={'flex-fill'}>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="m-0" controlId="formBasicEmail">
                                            <Form.Control type="text" maxLength={75} onChange={handleOnChange} placeholder="Start typing..." value={message} />
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col xs={'auto'} className={'px-2 px-lg-3 d-flex align-items-center'}>
                                    <span className='p-2'><PaperPlaneIcon onClick={handleSubmit} /></span>
                                </Col>
                            </div>
                        </> :
                        <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center p-4 p-lg-5">
                            <div className="p-4 w-50">
                                <img src="/images/No-Chat.svg" alt="" className='img-fluid' />
                            </div>
                            <Typography variant={'heading2'} label={'No messages yet'}></Typography>
                            <Typography variant={"dynamic-text"} fontSize={'text-15'} fontWeight={'font-medium'} color={'color-gray'} margin="mb-0 text-center" label={'No messages in your inbox, yet! Start chatting with people around you.'} />
                        </div>
                    }
                </Card>
            </div>
        </>
    )
}

export default React.memo(ChatBody)