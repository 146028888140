
import './App.scss';
import DefaultRoutes from './Routes';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="App">
      <ToastContainer autoClose={1000}
        position="bottom-right"
      />
      <DefaultRoutes />
    </div>
  );
}

export default App;
