import axios from "axios";
let token = (localStorage.getItem('loggedIn') && JSON.parse(localStorage.getItem('loggedIn') || '')?.token) || ('')

const api = axios.create({
   baseURL: process.env.REACT_APP_API_KEY,
   timeout: 30000,
   transformRequest: [...axios.defaults.transformRequest],
   transformResponse: [...axios.defaults.transformResponse],
   headers: { ...axios.defaults.headers }
});
if (token) {
   api.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

api.interceptors.request.use(function (config) {
   return config;
}, function (error) {
   return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
   return response;
}, function (error) {
   if (error?.response?.status === 401) {
      window.location.href = "/auth/login";
      localStorage.removeItem('loggedIn')

   }
   return Promise.reject(error);
});

export default api;