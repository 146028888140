import React, { useEffect, useState } from 'react'
import Downshift from 'downshift'
import CustomCheckbox from '../Checkbox'
import { FilterClose } from '../SVGIcons'
import './index.scss'


let timeout = 0;
export const CountrySuggestion = ({ placeholder, setMenuOpen, menuIsOpen, handleSearchFilter, userRole, className, list }) => {

    const [menuList, setMenuList] = useState([])
    const fetchStyles = (item) => item?.isChecked ? 'active' : ''


    useEffect(() => {
        setMenuList(list)
        return () => {
            setMenuList([])
        }
    }, [menuList, list])


    const handleChooseItem = (element) => {

        let newList = [...menuList]
        const index = newList.findIndex(item => item?.customerName === element?.customerName)
        newList[index].isChecked = newList[index]?.isChecked === 0 ? 1 : 0
        setMenuList(newList)
        handleSearchFilter(placeholder, element)
    }

    const handleCloseList = () => {
        setMenuList([]);
        setMenuOpen(false)
    }

    const onInputValueChange = (selection) => {
        if (selection !== undefined) {
            setMenuOpen(true)
            getSearchByCustomer(selection)
        }
    }

    const getSearchByCustomer = async (searchText) => {

        if (timeout) {
            clearTimeout(timeout);
            timeout = 0;
        }

        if (searchText?.length) {

        } else {
            setMenuList([])
        }
    }

    return (
        <div className={`search-select ${className ? className : ''}`} >
            <Downshift
                onOuterClick={handleCloseList}
                onChange={(selection) => handleChooseItem(selection)}
                isOpen={menuIsOpen}
                onInputValueChange={onInputValueChange}
                itemToString={item => (item ? item.name?.toLowerCase() : '')}>
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    inputValue,
                    isOpen
                }) => (
                    <div className='search-filter'>

                        <input {...getInputProps()} placeholder={placeholder} className="form-control" />
                        {<>
                            <ul {...getMenuProps()}>
                                {isOpen && <>
                                    {menuList?.length > 0
                                        ? menuList.filter(item => !inputValue || item.customerName?.toLowerCase().includes(inputValue.toLowerCase()))
                                            .map((item, index) => (
                                                <li

                                                    {...getItemProps({
                                                        key: item.customerName,
                                                        index,
                                                        item,
                                                    })}
                                                    className={`search-list ${fetchStyles(item)}`}>
                                                    <CustomCheckbox variant="labelled"
                                                        checked={Boolean(item?.isChecked)}
                                                        label={item?.customerName}
                                                        onChange={() => handleChooseItem(item)} />

                                                </li>
                                            )) :
                                        (<div className='user-not-found'>
                                            <p>No User found</p>
                                        </div>)
                                    }

                                </>}

                            </ul>
                            {isOpen && <div className='suggestion-close '>
                                <FilterClose onClick={handleCloseList} />
                            </div>}

                        </>}
                    </div>
                )}
            </Downshift>
        </div>
    )
}

