import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownButton, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Typography, SnackMessages } from '..';
import { actionGetUserDataWithToken, actionLogout } from '../../actions/auth';
import { LogoutIcon, ProfileIcon, SettingsIcon } from '../SVGIcons';
import "./index.scss";

export const AppBar = () => {
    const { user } = useSelector((state) => state?.authReducer)
    const { showSuccessMessage } = SnackMessages();
    const [isLoading, setLoading] = useState(true)
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const profileOptions = [
        { id: 1, name: 'My Profile', icon: ProfileIcon, path: '/dashboard/profile' },
        { id: 2, name: 'Settings', icon: SettingsIcon, path: '/dashboard/settings' },
        { id: 3, name: 'Logout', icon: LogoutIcon }

    ]

    const chooseProfileOption = async (option) => {
        option?.path && navigate(option?.path)
        if (option.name === 'Logout') {
            const res = await dispatch(actionLogout());
            if (res.success) {
                localStorage.removeItem('loggedIn')
                localStorage.removeItem('userData')
                showSuccessMessage("Logout Successfully")
                navigate('/auth/login')
            }
        }
    }

    const getAppBarTitle = React.useCallback(() => {
        const pathArray = location?.pathname.split('/').filter(path => path);
        const requestPathArray = window.location.href.split('=').filter(path => path);
        return (<>{requestPathArray[1] === "Request" ? <span className='title'>
            {requestPathArray?.length && <>
                {requestPathArray[0].includes("details") ? "Order Request Details" : "Orders"}
            </>}
        </span> :
            <span className='title'>
                {pathArray?.length && <>
                    {pathArray?.length === 1 || pathArray?.length === 2 ? pathArray[0].replace('-', ' ')
                        : pathArray?.length === 3 ? ((pathArray[0])?.replace('-', ' ') + ' ' + (pathArray[pathArray?.length - 1])?.replace('-', ' ') || '')
                            : ""}
                </>}
            </span>}</>)
    }, [location])

    const handleClickProfile = () => document.getElementById("profile-options").click();

    const getUserByAccessToken = React.useCallback(async () => {
        const { showErrorMessage } = SnackMessages();
        const res = await dispatch(actionGetUserDataWithToken());
        if (!res.success) {
            showErrorMessage(res.message)
        }
        setLoading(false)
    }, [dispatch])



    useEffect(() => {
        if (isLoading) {
            getUserByAccessToken()
        }
    }, [isLoading, getUserByAccessToken])


    return (
        <div className='appbar'>
            <div className='appbar-left col-10'>
                <Typography variant={'heading2'} label={getAppBarTitle()} />
            </div>
            <div className='d-flex justify-content-end m-auto col-2'>
                <InputGroup className="mb-3 profile" onClick={handleClickProfile}>
                    <Avatar variant={'header-pro-thumb'}
                        bsPrefix src={user?.profileImage || 'https://api.tab.devateam.com/images/avatar.png'} />
                    <DropdownButton
                        variant="outline-secondary"
                        title=""
                        id="profile-options">
                        {profileOptions?.length > 0 &&
                            profileOptions?.map((option) =>
                                <div key={option.id}>
                                    <div className='d-flex align-items-center gap-1 menu-options' onClick={() => chooseProfileOption(option)}>
                                        <option.icon />
                                        <Dropdown.Item href="#">{option?.name}</Dropdown.Item>
                                    </div>
                                </div>)}
                    </DropdownButton>
                </InputGroup>
            </div>
        </div>
    )
}
