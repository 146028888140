
import {
    LOGIN_FAILURE,
    LOGIN_BEGINS, LOGIN_SUCCESS, LOGOUT_BEGINS, LOGOUT_SUCCESS, LOGOUT_FAILURE, SEND_FORGOT_PASSWORD_BEGINS,
    SEND_FORGOT_PASSWORD_SUCCESS, SEND_FORGOT_PASSWORD_FAILURE, RESET_PASSWORD_BEGINS, RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    SEND_OTP_BEGINS,
    // SEND_OTP_SUCCESS,
    SEND_OTP_FAILURE,
    SEND_OTP_SUCCESS,
    GET_USER_DATA_WITH_AUTH_BEGINS,
    GET_USER_DATA_WITH_AUTH_SUCCESS,
    GET_USER_DATA_WITH_AUTH_FAILURE
} from '../../constants';
import api from '../../utils/axios';


const loginBegins = () => ({ type: LOGIN_BEGINS })

const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, data })

const loginFails = () => ({ type: LOGIN_FAILURE })

const logoutBegins = () => ({ type: LOGOUT_BEGINS })

const logoutSuccess = () => ({ type: LOGOUT_SUCCESS })

const logoutFails = () => ({ type: LOGOUT_FAILURE })

const forgotPasswordBegins = () => ({ type: SEND_FORGOT_PASSWORD_BEGINS })

const forgotPasswordSuccess = (data) => ({ type: SEND_FORGOT_PASSWORD_SUCCESS, data })

const forgotPasswordFails = () => ({ type: SEND_FORGOT_PASSWORD_FAILURE })

const resetPasswordBegins = () => ({ type: RESET_PASSWORD_BEGINS })

const resetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS })

const resetPasswordFails = () => ({ type: RESET_PASSWORD_FAILURE })

const sendOTPBegins = () => ({ type: SEND_OTP_BEGINS })

const sendOTPSuccess = () => ({ type: SEND_OTP_SUCCESS })

const sendOTPFails = () => ({ type: SEND_OTP_FAILURE })



export const getUserDataWithAuthBegins = () => ({
    type: GET_USER_DATA_WITH_AUTH_BEGINS
})

export const getUserDataWithAuthSuccess = (data) => ({
    type: GET_USER_DATA_WITH_AUTH_SUCCESS,
    data
})

export const getUserDataWithAuthFailure = () => ({
    type: GET_USER_DATA_WITH_AUTH_FAILURE
})


export const actionLogin = (data) => async (dispatch) => {

    dispatch(loginBegins());
    try {
        const res = await api.post('/login', data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Login Successful' };
            const { first_login } = res?.data || { first_login: "0" };
            const { access_token } = res?.data || { access_token: '' };
            dispatch(loginSuccess({ token: access_token, email: String(first_login) === '1' ? data.email : '', first_login: first_login }));
            api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`
            return { success: true, message: message, token: access_token || '', first_login: first_login }
        }

    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(loginFails())
        return { success: false, message }
    }
}

export const actionLogout =  () => async (dispatch) => {

    dispatch(logoutBegins());
    try {
        const res = await api.post('/logout')
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Logout Successful' };
            dispatch(logoutSuccess())
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(logoutFails())
        return { success: false, message }
    }
}

export const actionForgotPassword = (data) => async (dispatch) => {
    dispatch(forgotPasswordBegins());
    try {
        const res = await api.post('/forget-password', data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Email sended to your mail id. You can reset your password from your mail' };
            const { key } = res?.data || { key: '' }
            dispatch(forgotPasswordSuccess({ key: key, email: data.email }));
            return { success: true, message: message, }
        }

    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(forgotPasswordFails())
        return { success: false, message }
    }
}

export const actionResetPassword = (url, data) => async (dispatch) => {
    dispatch(resetPasswordBegins());
    try {
        const res = await api.post(url, data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Email sended to your mail id. You can reset your password from your mail' };
            dispatch(resetPasswordSuccess())
            return { success: true, message: message, }
        }

    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(resetPasswordFails())
        return { success: false, message }
    }
}


export const actionSendOTP = (data) => async (dispatch) => {
    dispatch(sendOTPBegins());
    try {
        const res = await api.post('/check-otp', data)
        if (res.status === 200) {
            const { message } = res?.data || { message: 'OTP verified successfully' };
            dispatch(sendOTPSuccess())
            return { success: true, message: message, }
        }

    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(sendOTPFails())
        return { success: false, message }
    }
}
export const actionGetUserDataWithToken = () => async (dispatch) => {
    dispatch(getUserDataWithAuthBegins());
    try {
        const res = await api.get(`user-details-by-access-token`)
        if (res.status === 200) {
            const { data } = res.data || { data: { data: {} } };
            localStorage.setItem('userData', JSON.stringify(data))
            dispatch(getUserDataWithAuthSuccess(data))
            return { success: true, message: 'success' }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(getUserDataWithAuthFailure())
        return { success: false, message: message, data: [] }
    }

}